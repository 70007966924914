import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSGBLCompanyMoreDetailQuery, useGetSGBLCompanyInfoDetailQuery } from 'redux/api/Topsgbl';
import {
  Box,
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  TableHead,
  TablePagination,
  TableFooter,
  Card,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 'max-content',
    borderLeft: '3px solid',
    borderLeftColor: theme.palette.primary.main,
    borderRight: '3px solid',
    borderRightColor: theme.palette.primary.main,
    borderBottom: '3px solid',
    borderBottomColor: theme.palette.primary.main,
    background: 'white',
  },
  leftTable: {
    borderLeft: '3px solid',
    borderLeftColor: theme.palette.primary.main,
    borderRight: '3px solid',
    borderRightColor: theme.palette.primary.main,
    borderBottom: '3px solid',
    borderBottomColor: theme.palette.primary.main,
    background: 'white',
  },
  container: {
    width: '100%',
    borderRadius: 6,
    marginTop: 6,
    overflowX: 'auto', // Allows horizontal scrolling
  },
  tableHeader: {
    backgroundColor: '#028400',
    color: 'white', // Set header text color to white
  },
  sectionLeft: {
    flex: '0 0 32%', // Fixed width for the left section
    marginRight: '10px',
    boxSizing: 'border-box', // Include padding and border in width calculation
    overflow: 'hidden', // Prevent content from overflowing
  },
  sectionRight: {
    flex: '1', // Takes the remaining space
    boxSizing: 'border-box',
    overflowX: 'hidden',
  },
  divider: {
    height: '3.5px',
    backgroundColor: '#028400',
    marginTop: '20px',
    marginBottom: '20px',
    width: '100%',
  },
  layout: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 20,
  },
  tableFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  headerCell: {
    backgroundColor: '#028400', // Header background color
    color: 'white', // Header text color
  },
  appCard: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    position: 'relative',
    overflow: 'hidden',
    padding: 16,
    height: '110px',
  },
  appCardLeftSection: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    position: 'relative',
    overflow: 'hidden',
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    width: '90%',
  },
  cardBar: {
    width: 5, // Adjust the width of the color bar here
    height: '100%',
    backgroundColor: '#028400', // Color for the bar
    position: 'absolute',
    left: 0,
    top: 0,
  },
  cardContent: {
    flex: 1,
    paddingLeft: 16,
  },
  cardGrid: {
    marginBottom: 20,
  },
  cardTitle: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginBottom: '8px', // Adjust margin to move title away from content
    color: 'black',
  },
  cardTextBlack: {
    color: 'black',
    fontSize: '1.25rem',
  },
  cardTextGreen: {
    color: '#028400',
    fontSize: '22px',
    fontWeight: 'bold',
  },
  cardTextOrange: {
    color: 'orange',
    fontSize: '15px',
  },
  cardTextAge: {
    fontSize: '1.2rem',
    color: '#555', // Grey color for the age
    marginBottom: '8px',
  },
  underline: {
    width: '100%',
    height: '3px',
    backgroundColor: '#028400', // Green underline
    marginBottom: '16px', // Padding below the line
  },
  // appCardLeftSection: {
  //   backgroundColor: theme.palette.background.paper,
  //   borderRadius: 4,
  //   position: 'relative',
  //   overflow: 'hidden',
  //   padding: 16,
  //   width: '95%',
  //   height: 'flex', // Adjust height as needed
  // },

  leftCardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align items to the start of the card
  },

  leftCardTitle: {
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#3d3d3d', // Green color for the name
    marginBottom: '10px', // Adjusted margin for better spacing
  },

  leftCardTextAge: {
    fontSize: '1rem',
    color: '#555', // Grey color for the age
    marginBottom: '8px', // Margin below the age text
  },

  leftUnderline: {
    width: '100%', // Full width of the card
    height: '1px',
    backgroundColor: '#028400', // Green underline
    marginBottom: '20px', // Padding below the underline
    marginTop: '8px', // Adjust this value to move the line lower
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '8px', // Space between fields
  },
  fieldLabel: {
    fontSize: '1.10rem', // Slightly larger font size for labels
    color: '#b8b8b8', // Grey color for labels
    width: '150px', // Fixed width for consistent alignment
    textAlign: 'left', // Align text to the left
    marginRight: '100px', // Space between label and value
    whiteSpace: 'nowrap',
    marginBottom: '4px',
  },
  fieldValue: {
    fontSize: '1rem', // Same size for values as labels
    color: 'black', // Black color for values
  },
}));

const DetailScreenCorp = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Fetch data for the individual using the query hook
  const { data, error, isLoading } = useGetSGBLCompanyMoreDetailQuery(id);

  // const { individualInfo, errorInfo, isLoadingInfo } = useGetSGBLIndividualInfoMoreDetailQuery(id);
  const { data: corpInfo = null, errorInfo, isLoadingInfo } = useGetSGBLCompanyInfoDetailQuery(id);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 on rows per page change
  };

  if (isLoading || isLoadingInfo) return <CircularProgress />;
  if (error || errorInfo) return <div>Error loading details: {error?.message || errorInfo?.message}</div>;

  const rows = data || [];
  const paginatedRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const leftSectionRow = corpInfo || [];

  // Calculate totals
  // Assuming `data` contains the full dataset
  const totalOSGAUSD = data.reduce((sum, row) => {
    const value = parseFloat(row.cgcc_osgausdequ) || 0; // Convert to number, default to 0 if NaN
    return sum + value;
  }, 0);

  const totalGuaAmountUSD = data.reduce((sum, row) => {
    const value = parseFloat(row.guaamtusdequ) || 0; // Convert to number, default to 0 if NaN
    return sum + value;
  }, 0);

  const totalGuaAccounts = data.length;

  // Count active Gua. Status
  const activeCount = data.filter((row) => row.guastatus === 'Active').length;

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `${formatter.format(amount)}`;
  };

  const formatDate = (date) => {
    if (!date) return 'N/A';

    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(d.getDate()).padStart(2, '0'); // Pad single-digit days with a leading zero

    return `${year}-${month}-${day}`;
  };

  // Extract the first unique individual info record
  const firstCorpInfo = corpInfo && corpInfo.length > 0 ? corpInfo[0] : null;
  return (
    <div>
      <h1>
        <span style={{ color: 'black', fontSize: '22px' }}>Profile</span>
        <span style={{ color: '#028400', fontSize: '22px' }}> | {id}</span>
      </h1>
      <div className={classes.divider} />

      {/* Divided UI into two sections */}
      <div className={classes.layout}>
        {/* Left Section */}
        <div className={classes.sectionLeft}>
          <Grid container spacing={3} className={classes.cardGrid}>
            <Grid item xs={12}>
              <Card className={classes.appCardLeftSection}>
                {firstCorpInfo ? (
                  <div className={classes.leftCardContent}>
                    <Typography variant="h5" className={classes.leftCardTitle}>
                      {firstCorpInfo.companyName}
                    </Typography>
                    <div className={classes.leftUnderline} />
                    {/* Type Of Biz */}
                    <div className={classes.fieldContainer}>
                      <Typography variant="body1" className={classes.fieldLabel}>
                        Biz Type:
                      </Typography>
                      <Typography variant="body1" className={classes.fieldValue}>
                        {firstCorpInfo.typeOfBusiness || 'N/A'}
                      </Typography>
                    </div>

                    {/* BusiessSector */}
                    <div className={classes.fieldContainer}>
                      <Typography variant="body1" className={classes.fieldLabel}>
                        Business Sector:
                      </Typography>
                      <Typography variant="body1" className={classes.fieldValue}>
                        {firstCorpInfo.businessSector || 'N/A'}
                      </Typography>
                    </div>

                    {/* City */}
                    <div className={classes.fieldContainer}>
                      <Typography variant="body1" className={classes.fieldLabel}>
                        City / Province:
                      </Typography>
                      <Typography variant="body1" className={classes.fieldValue}>
                        {firstCorpInfo.cityname || 'N/A'}
                      </Typography>
                    </div>
                    {/* District */}
                    <div className={classes.fieldContainer}>
                      <Typography variant="body1" className={classes.fieldLabel}>
                        Khan / District:
                      </Typography>
                      <Typography variant="body1" className={classes.fieldValue}>
                        {firstCorpInfo.districtname || 'N/A'}
                      </Typography>
                    </div>
                    {/* Commune */}
                    <div className={classes.fieldContainer}>
                      <Typography variant="body1" className={classes.fieldLabel}>
                        Sankat / Commune:
                      </Typography>
                      <Typography variant="body1" className={classes.fieldValue}>
                        {firstCorpInfo.communename || 'N/A'}
                      </Typography>
                    </div>
                    {/* Village */}
                    <div className={classes.fieldContainer}>
                      <Typography variant="body1" className={classes.fieldLabel}>
                        Phumi / Village:
                      </Typography>
                      <Typography variant="body1" className={classes.fieldValue}>
                        {firstCorpInfo.villagename || 'N/A'}
                      </Typography>
                    </div>
                    {/*table in left section*/}
                    <TableContainer className={classes.container}>
                      <Table className={classes.leftTable}>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.headerCell}>No</TableCell>
                            <TableCell className={classes.headerCell}>Description</TableCell>
                            <TableCell className={classes.headerCell}>Ref.No</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {leftSectionRow.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                              <TableCell>{item.licname || 'N/A'}</TableCell>
                              <TableCell>{item.referenceNo || 'N/A'}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/*end table in left section*/}
                  </div>
                ) : (
                  <Typography>No Corp info available</Typography>
                )}
              </Card>
            </Grid>
          </Grid>
        </div>

        {/*end left section*/}

        {/* Right Section */}
        <div className={classes.sectionRight}>
          {/* Card section */}
          <Grid container spacing={3} className={classes.cardGrid}>
            <Grid item xs={12} md={4}>
              <Card className={classes.appCard}>
                <Box className={classes.cardBar} />
                <Box className={classes.cardContent}>
                  <Typography variant="h6" className={classes.cardTitle}>
                    Total OSGA USD Equ.
                  </Typography>
                  <Typography variant="body2" className={classes.cardTextBlack}>
                    USD <span className={classes.cardTextGreen}>{formatCurrency(totalOSGAUSD)}</span>
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className={classes.appCard}>
                <Box className={classes.cardBar} />
                <Box className={classes.cardContent}>
                  <Typography variant="h6" className={classes.cardTitle}>
                    Total Gua. Amt USD Equ.
                  </Typography>
                  <Typography variant="body2" className={classes.cardTextBlack}>
                    USD <span className={classes.cardTextGreen}>{formatCurrency(totalGuaAmountUSD)}</span>
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className={classes.appCard}>
                <Box className={classes.cardBar} />
                <Box className={classes.cardContent}>
                  <Typography variant="h6" className={classes.cardTitle}>
                    Total Gua. Accounts
                  </Typography>
                  <Typography variant="body2">
                    <span className={classes.cardTextGreen}>{totalGuaAccounts} GA</span>
                  </Typography>
                  <Typography variant="body2" className={classes.cardTextOrange}>
                    Active: {activeCount} GA
                  </Typography>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <TableContainer className={classes.container}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerCell}>No</TableCell>
                  <TableCell className={classes.headerCell}>LG No</TableCell>
                  <TableCell className={classes.headerCell}>PFI</TableCell>
                  <TableCell className={classes.headerCell}>Effective Date</TableCell>
                  <TableCell className={classes.headerCell}>Scheme</TableCell>
                  <TableCell className={classes.headerCell}>Currency</TableCell>
                  <TableCell className={classes.headerCell}>Gua.Loan </TableCell>
                  <TableCell className={classes.headerCell}>Gua.Loan USD Equ.</TableCell>
                  <TableCell className={classes.headerCell}>Gua.Amt</TableCell>
                  <TableCell className={classes.headerCell}>Gua.Amt USD Equ.</TableCell>
                  <TableCell className={classes.headerCell}>CGCC OSGA</TableCell>
                  <TableCell className={classes.headerCell}>Loan Status</TableCell>
                  <TableCell className={classes.headerCell}>Guarantee Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedRows.length > 0 ? (
                  paginatedRows.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                      <TableCell>{item.lgno}</TableCell>
                      <TableCell>{item.pfiname}</TableCell>
                      <TableCell>{formatDate(item.effectivedate)}</TableCell>
                      <TableCell>{item.scheme}</TableCell>
                      <TableCell>{item.loancurrency}</TableCell>
                      <TableCell>{formatCurrency(item.loanamount)}</TableCell>
                      <TableCell>{formatCurrency(item.gualoanusdequ)}</TableCell>
                      <TableCell>{formatCurrency(item.guaamt)}</TableCell>
                      <TableCell>{formatCurrency(item.guaamtusdequ)}</TableCell>
                      <TableCell>{formatCurrency(item.cgcc_osgausdequ)}</TableCell>
                      <TableCell
                        style={{
                          color:
                            item.loanstatus === 'Normal'
                              ? '#028400'
                              : item.loanstatus === 'Special Mention'
                              ? 'orange'
                              : item.loanstatus === 'Sub Standard'
                              ? 'orange'
                              : item.loanstatus === 'Doubtful'
                              ? 'orange'
                              : item.loanstatus === 'Loss'
                              ? 'red'
                              : item.loanstatus === 'Close'
                              ? 'grey'
                              : 'black', // Default color if status is unknown
                        }}>
                        {item.loanstatus || 'N/A'}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            item.guastatus === 'Active' ? '#028400' : item.guastatus === 'Inactive' ? 'orange' : 'black',
                        }}>
                        {item.guastatus}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={11} align="center">
                      No data found for this ID.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="td"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default DetailScreenCorp;
