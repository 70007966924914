import React from 'react';
import { TableCell, withStyles } from '@material-ui/core';
import moment from 'moment';
import IntlMessages from '@jumbo/utils/IntlMessages';

export const primaryColor = '#028400';
export const secondaryColor = '#0284001A';
export const orangeColor = '#F27405';
export const redColor = '#D5001A';
export const cyanColor = '#007BB0';
export const yellowColor = '#FFA500';
export const blackColor = '#000000';
export const grayColor = '#404040';

// Available options for rows per page in pagination
export const paginationRowOptionsShort = [10, 20, 50, 100, 200, 500, 1000];

// Extended options for rows per page in pagination
export const paginationRowOptionsMedium = [...paginationRowOptionsShort, 2000, 5000];

// Comprehensive options for rows per page in pagination
export const paginationRowOptionsLong = [...paginationRowOptionsMedium, 10000];

// Format
export const BILLING_DATE_FORMAT = 'DD - MMM - YYYY';
export const BILLING_DATETIME_FORMAT = 'DD - MMM - YYYY h:mm A';

export const formula = (val) => {
  return <span style={{ color: primaryColor }}>{val}</span>;
};

export function getBackgroundColor(billType) {
  if (billType === 'Available Bill') {
    return primaryColor;
  } else if (billType === 'Late Bill') {
    return redColor;
  } else if (billType === 'Sent Bill') {
    return cyanColor;
  } else if (billType === 'Pending Bill') {
    return yellowColor;
  } else {
    return blackColor;
  }
}

export const formatUploadStatus = (status) => {
  if (status === 'pending' || status === 'pending_report') {
    return 'Pending';
  } else if (status === 'waiting_approved') {
    return 'Awaiting';
  } else if (status === 'approved') {
    return 'Completed';
  } else if (status === 'partial') {
    return 'Partial';
  } else if (status === 'completed') {
    return 'Completed';
  } else {
    return 'N/A';
  }
};

export const formatUploadStatusColor = (status) => {
  if (status?.toLowerCase() === 'pending' || status?.toLowerCase() === 'pending_report') {
    return redColor;
  } else if (status?.toLowerCase() === 'waiting_approved') {
    return cyanColor;
  } else if (status?.toLowerCase() === 'approved') {
    return primaryColor;
  } else if (status?.toLowerCase() === 'completed') {
    return primaryColor;
  } else if (status?.toLowerCase() === 'partial') {
    return orangeColor;
  } else {
    return grayColor;
  }
};

// Payment Received
export const formatPaymentReceived = (status) => {
  if (!!status) {
    if (status?.toLowerCase() === 'partial') {
      return 'Partial Paid';
    } else {
      return formatCapitalize(status);
    }
  } else {
    return 'Due';
  }
};

export function invoiceFormat(invoice) {
  return `${invoice.prefix}-${invoice.year.slice(-2)}-${invoice.reference}`;
}

// Payment Status
export const formatPaymentStatus = (status) => {
  if (!!status) {
    if (status?.toLowerCase() === 'partial') {
      return 'Partial Paid';
    } else {
      return formatCapitalize(status);
    }
  } else {
    return 'Due';
  }
};

// Claim Payment Status
export const formatClaimPaymentStatus = (status) => {
  const lowerStatus = status?.toLowerCase();
  if (lowerStatus === 'partial' || lowerStatus === 'partial_paid') {
    return 'Partial Paid';
  } else if (lowerStatus === 'paid' || lowerStatus === 'full_paid') {
    return 'Fully Paid';
  } else {
    return 'Unpaid';
  }
};

// Payment Received Color
export const formatPaymentReceivedColor = (status) => {
  if (status?.toLowerCase() === 'paid') {
    return primaryColor;
  } else if (status?.toLowerCase() === 'due') {
    return redColor;
  } else {
    return orangeColor;
  }
};
// Payment Status Color
export const formatPaymentStatusColor = (status) => {
  if (status?.toLowerCase() === 'paid') {
    return primaryColor;
  } else if (status?.toLowerCase() === 'due') {
    return redColor;
  } else {
    return orangeColor;
  }
};

//Claim Payment Status
export const formatClaimPaymentStatusColor = (status) => {
  const lowerStatus = status?.toLowerCase();

  if (lowerStatus === 'fully_paid' || lowerStatus === 'paid') {
    return primaryColor;
  } else if (lowerStatus === 'partial_paid' || lowerStatus === 'partial') {
    return orangeColor;
  } else {
    return redColor;
  }
};

export const formatClaimInfoStatusColor = (status) => {
  if (status?.toLocaleLowerCase() === 'paid' || status?.toLocaleLowerCase() === 'fully paid') {
    return primaryColor;
  } else {
    return orangeColor;
  }
};

// Payment Status for All Invoice
export const formatPaymentStatusForAllInvoice = (status) => {
  if (status?.toLowerCase() === 'paid') {
    return primaryColor;
  } else if (status?.toLowerCase() === 'overdue') {
    return redColor;
  } else {
    return orangeColor;
  }
};

export const formatActiveColor = (status) => {
  if (status?.toLowerCase() === 'active') {
    return primaryColor;
  } else {
    return redColor;
  }
};

export const formatReconcileStatusColor = (status) => {
  if (status?.toLowerCase() === 'completed') {
    return primaryColor;
  } else if (status?.toLowerCase() === 'waiting_approved') {
    return cyanColor;
  } else {
    return redColor;
  }
};

export const titleClaimStatus = (rows) => {
  if (!!rows) {
    if (rows?.claimStage === 'after_second_claim') {
      if (rows?.claimInfo?.firstClaimStatus === 'approved' && rows?.claimInfo?.secondClaimStatus === 'approved') {
        return <IntlMessages id="recovery.claim.status" />;
      } else {
        return <IntlMessages id="recovery.second.claim.status" />;
      }
    } else if (rows?.claimStage === 'before_second_claim' && rows?.claimStage === 'before_second_claim_payment') {
      return <IntlMessages id="recovery.second.claim.status" />;
    } else {
      return <IntlMessages id="recovery.first.claim.status" />;
    }
  }
};

export const valueClaimStatus = (rows) => {
  if (!!rows) {
    if (rows?.claimStage === 'after_second_claim') {
      if (rows?.claimInfo?.firstClaimStatus === 'approved' && rows?.claimInfo?.secondClaimStatus === 'approved') {
        return 'APPROVED';
      } else {
        return formatUpperCase(rows?.claimInfo?.secondClaimStatus);
      }
    } else if (rows?.claimStage === 'before_second_claim' && rows?.claimStage === 'before_second_claim_payment') {
      return formatUpperCase(rows?.claimInfo?.secondClaimStatus);
    } else {
      return formatUpperCase(rows?.claimInfo?.firstClaimStatus);
    }
  }
};

export const formatClaimStatus = (status) => {
  if (!!status) {
    if (status?.toLowerCase() === 'partial') {
      return 'Partial Paid';
    } else {
      return formatCapitalize(status);
    }
  } else {
    return 'N/A';
  }
};

export const formatClaimStatusColor = (status) => {
  if (status?.toLowerCase() === 'due' || status?.toLowerCase() === 'unpaid') {
    return redColor;
  } else if (status?.toLowerCase() === 'partial' || status?.toLowerCase() === 'partial_paid') {
    return orangeColor;
  } else if (status?.toLowerCase() === 'paid') {
    return primaryColor;
  } else {
    return blackColor;
  }
};

export const formatAccrualStatusColor = (status) => {
  if (status?.toLowerCase() === 'pending') {
    return redColor;
  } else if (status?.toLowerCase() === 'posted' || status?.toLowerCase() === 'issued') {
    return primaryColor;
  } else {
    return orangeColor;
  }
};

export const generateProvisionStatusColor = (status) => {
  if (status?.toLowerCase() === 'pending') {
    return redColor;
  } else if (status?.toLowerCase() === 'generated' || status?.toLowerCase() === 'issued') {
    return primaryColor;
  } else {
    return orangeColor;
  }
};

export const provisionStatusColor = (status) => {
  if (status?.toLowerCase() === 'pending') {
    return redColor;
  } else if (status?.toLowerCase() === 'posted' || status?.toLowerCase() === 'issued') {
    return primaryColor;
  } else {
    return orangeColor;
  }
};

export const approvedStatusColor = (status) => {
  if (status === 'rejected') {
    return redColor;
  } else if (status === 'approved') {
    return primaryColor;
  } else {
    return orangeColor;
  }
};

export const paidClaimStatus = (status) => {
  if (status?.toLowerCase() === 'partial_paid') {
    return orangeColor;
  } else {
    return primaryColor;
  }
};

export const transactionType = (transactionType) => {
  if (transactionType?.toLowerCase() === 'claim_request') {
    return redColor;
  } else if (transactionType?.toLowerCase() === 'claim_payment_in_progress') {
    return orangeColor;
  } else {
    return primaryColor;
  }
};

export const formatCurrencyUSD = (num) => num?.toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$&,');

export const formatCurrencyKHR = (num) => num?.toFixed(0).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$&,');

export const formatCurrency = (currency, val = 0, { accountingFormat } = { accountingFormat: true }) => {
  if (val === 'N/A') return 'N/A';

  if (accountingFormat) {
    const formattedValue = currency === 'USD' ? formatCurrencyUSD(Math.abs(val)) : formatCurrencyKHR(Math.abs(val));
    if (val < 0) {
      return `(${formattedValue})`;
    }
    return formattedValue;
  }

  return currency === 'USD' ? formatCurrencyUSD(val) : formatCurrencyKHR(val);
};

export const fullFormatCurrency = (currency, amount) => {
  if (!!amount) {
    if (currency === 'USD') {
      return amount < 0
        ? `${currency} (${formatCurrencyUSD(Math.abs(amount))})`
        : `${currency} ${formatCurrencyUSD(amount)}`;
    } else {
      return amount < 0
        ? `${currency} (${formatCurrencyKHR(Math.abs(amount))})`
        : `${currency} ${formatCurrencyKHR(amount)}`;
    }
  } else {
    return currency === 'USD' ? `${currency} ${formatCurrencyUSD(0)}` : `${currency} ${formatCurrencyKHR(0)}`;
  }
};

export const nplFullFormatCurrency = (currency, amount) => {
  const formatAmount = (value) => value?.toString()?.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$&,');

  const formattedAmount = amount ? formatAmount(amount) : formatAmount(0);

  return `${currency} ${formattedAmount}`;
};

// Convert Full Month and Year
export const formatMonthYear = (month, year) => {
  return month ? moment(`${year}-${month}`, 'YYYY-M').format('MMMM - YYYY') : 'N/A';
};

// Convert Month 1 -> 01
export const formatMonthToString = (month) => {
  return month.toString().padStart(2, '0');
};

export const formatCapitalize = (val) => {
  if (!!val) {
    const words = val.split('_');
    const CapitalizeWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return CapitalizeWords.join(' ');
  } else {
    return 'N/A';
  }
};

export const formatUpperCase = (val) => {
  if (!!val) {
    const words = val.split('_');
    const UpperWords = words.map((word) => word.toUpperCase());
    return UpperWords.join(' ');
  } else {
    return 'N/A';
  }
};

export function formatBillingType(value) {
  switch (value) {
    case 'issue_lg':
      return 'Issue LG';
    case 'cancel_lg':
      return 'Cancel LG';
    case 'early_settlement':
      return 'Early Loan Settlement';
    case 'r_and_r':
      return 'R&R';
    default:
      return formatCapitalize(value);
  }
}

export const formatInvoiceType = (type) => {
  if (!!type) {
    if (type?.toLowerCase() === 'invoice') {
      return 'Tax Invoice';
    }
    if (type?.toLowerCase() === 'skipped_invoice') {
      return 'Skipped Tax Invoice';
    } else {
      return formatCapitalize(type);
    }
  } else {
    return 'N/A';
  }
};

export const handleBillingType = (type, currency, val) => {
  if (type === 'Cancel LG') {
    return `(${formatCurrency(currency, val)})`;
  } else {
    return formatCurrency(currency, val);
  }
};

export const formatPenaltyRate = (row) => {
  if (!!row?.rate) {
    if (row?.penaltyMode === 'fixed_amount') {
      return formatCurrency(row?.currency, row?.rate);
    } else {
      return `${row?.rate} %`;
    }
  } else {
    return 'N/A';
  }
};

export const formatBill = (num) => {
  if (!!num) {
    if (num < 2) {
      return num + ' LG';
    } else {
      return num + ' LGs';
    }
  } else {
    return 'N/A';
  }
};

export const formatStatus = (status) => {
  if (!!status) {
    if (status === 'Partially') {
      return 'Partially Completed';
    } else {
      return status;
    }
  } else {
    return 'N/A';
  }
};

export const formatStage = (stage) => {
  if (stage === 'create' || stage === 'op_reviewing') {
    return 'FBA Creator';
  } else if (stage === 'pending_reviewer' || stage === 'reviewer_reviewing') {
    return 'FBA Reviewer';
  } else {
    return 'FBA Authorizer';
  }
};

export const formatCgccStage = (stage) => {
  if (stage === 'cgcc_cop_review') {
    return 'COP REVIEW';
  } else if (stage === 'cgcc_cop_recommend') {
    return 'COP RECOMMENDATION';
  } else if (stage === 'cgcc_approval') {
    return 'APPROVAL';
  } else {
    return 'N/A';
  }
};

export const formatDay = (cronExp, day, intl) => {
  if (cronExp === 'weekly') {
    const matchingDay = days.find((dayObj) => dayObj.value === day.toString());
    return matchingDay.label;
  } else if (cronExp === 'monthly') {
    const getDaySuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };

    const menuItems = Array.from({ length: 31 }, (v, i) => ({
      value: (i + 1).toString(),
      label: (i + 1).toString() + getDaySuffix(i + 1),
    }));

    menuItems?.push({ value: 'end_of_month', label: intl.formatMessage({ id: 'end.of.month' }) });

    const matchingDay = menuItems.find((dayObj) => dayObj.value === day.toString());
    return matchingDay.label;
  } else {
    return 'N/A';
  }
};

export const claimLoanStatus = (status) => {
  if (status == '1') {
    return 'First Claim Payout';
  } else if (status == '2') {
    return 'Second Claim Payout';
  } else {
    return 'Total';
  }
};

export const formatProvisionType = (type) => {
  if (!type) return 'N/A';

  if (type === 'general') {
    return 'General Provision';
  } else {
    return 'Specific Provision';
  }
};

export const formatTime = (time) => {
  if (time) {
    return moment(time).format('hh:mm A');
  } else {
    return 'N/A';
  }
};

// Assign To Claim
export const showAssignTo = (row) => {
  if (row?.stage === 'approval' || row?.stage === 'cgcc_approval') {
    return row?.review;
  } else {
    if (row?.review && Object.keys(row.review).length > 0) {
      return `${row.review.name}`;
    } else {
      return 'N/A';
    }
  }
};

export const fullNameBorrower = (ind) => {
  let prefix = 'Mr. ';
  if (ind) {
    if (ind.gender === 'F') {
      prefix = 'Ms. ';
    }
    return (
      <span style={{ textTransform: 'capitalize' }}>
        {prefix} {ind.firstName} {ind.lastName}
      </span>
    );
  }
  return 'N/A';
};

export const mainBorrower = (row) => {
  if (!row) return 'N/A';

  if (row?.typeMainBorrower === 'COMPANY') {
    if (!!row?.business?.companyName) {
      return row?.business?.companyName;
    } else {
      if (row?.borrowers?.length > 0) {
        let main = row?.borrowers.find((i) => i.borrowerRelationship == 30 ?? 10);
        if (main) {
          return `${main?.firstName} ${main?.lastName}`;
        }
      }
    }
  }

  if (row?.borrowers?.length > 0) {
    let main = row?.borrowers.find((i) => i.borrowerRelationship == 10);
    if (main) {
      return `${main?.firstName} ${main?.lastName}`;
    }
  }
};

export const loanClassificationStatus = (status) => {
  switch (status) {
    case 'N':
      return 'Normal (N)';
    case 'S':
      return 'Special Mention (S)';
    case 'C':
      return 'Closed (C)';
    case 'U':
      return 'Sub Standard (U)';
    case 'D':
      return 'Doubtful (D)';
    case 'L':
      return 'Loss (L)';
    case 'W':
      return 'Write Off (W)';
    case 'R':
      return 'Reject (R)';
    default:
      return 'N/A';
  }
};

export const formatShortLoanClassification = (status) => {
  switch (status) {
    case 'normal':
      return 'N';
    case 'special_mention':
      return 'S';
    case 'close':
      return 'Closed';
    case 'substandard':
      return 'U';
    case 'doubtful':
      return 'D';
    case 'loss':
      return 'L';
    case 'write_off':
      return 'WO';
    case 'reject':
      return 'R';
    default:
      return 'N/A';
  }
};

export const SecurityType = [
  'CA: Cash',
  'MO: Mortgage',
  'NO: None',
  'OT: Other (Anything else not found in this list)',
  'SH: Shares',
  'PM: Multiple (Having more than one collateral)',
  'FD: Fixed Deposit',
  'LT: Land Title (Referred to "Hard Title")',
  'TO: Ownership Title-Land/Buildings (Referred to "Soft Title")',
  'FB: Floating Debenture',
  'MV: Motor Vehicle',
  'IN: Inventory',
  'LC: Letter of Credit',
  'CG: Corporate Guarantees',
  'GI: Guarantor',
];

export const formatFacilityType = (type) => {
  if (type === '0' || type === 'TLT') {
    return 'Term Loan (TL)';
  } else if (type === '1' || type === 'ODT') {
    return 'OverDraft (OD)';
  } else if (type === '2' || type === 'TFT') {
    return 'Trade Facility (TF)';
  } else {
    return 'N/A';
  }
};

export const facilityType = (loans) => {
  if (loans?.length > 0) {
    const loan = loans?.find((loan) => loan.outstandingBalance > 0);
    if (loan.loanType === '0') {
      return 'Term Loan (TL)';
    } else if (loan.loanType === '1') {
      return 'OverDraft (OD)';
    } else {
      return 'Trade Facility (TF)';
    }
  }
  return 'N/A';
};

export const interestRate = (loans, scheme) => {
  if (!loans?.length) return null;
  const loan = loans.find((loan) => loan.outstandingBalance > 0);
  if (!loan) return null;
  return scheme === 'WEGS' ? `${loan.interestRatePerAnnum1st}%` : `${loan.interestRatePerAnnum}%`;
};

export const totalCollateral = (row) => {
  if (row) {
    const collateral = row?.securityTypes?.map((collateral) => {
      const estimatedValue = collateral.estimatedValueSecurity;
      if (estimatedValue !== undefined) {
        return parseFloat(
          estimatedValue
            .toString()
            .replace(',', '')
            .replace('$', '')
            .replace('៛', '')
            .replace('KHR', '')
            .replace('USD', '')
            .replace(' ', ''),
        );
      } else {
        return 0; // You can choose another default value if needed
      }
    });
    const total = collateral?.reduce((acc, value) => acc + value, 0);
    return fullFormatCurrency(row?.currency, total);
  }
  return 'N/A';
};

export const loanPurposes = (loans) => {
  const loan = loans?.find((loan) => loan.outstandingBalance > 0);
  switch (loan?.loanPurpose) {
    case '0':
      return 'Working Capital';
    case '1':
      return 'Investment/Business Expansion';
    case '2':
      return 'Capital Expenditure';
    case '3':
      return 'Working Capital and Capital Expenditure';
    case '4':
      return 'New Investment and/or Business Expansion';
    case '5':
      return 'To support the settlement fund to/from True Money wallet.';
    case '6':
      return 'Working Capital and Investment/Business Expansion';
    default:
      return 'N/A';
  }
};

export const GuaranteeFeePerAnnum = (lg) => {
  let isPriority = false;
  let guaranteeFeePerAnnum = 0;
  if (lg) {
    if (lg.level && lg.level.priority && 'priority' === lg.level.priority.toLowerCase()) {
      isPriority = true;
    }
    if (isPriority) {
      guaranteeFeePerAnnum = lg.sectorFee;
    } else {
      guaranteeFeePerAnnum = lg.sectorFeeNonPriority;
    }
  }
  return guaranteeFeePerAnnum;
};

export const MaturityDate = (lg) => {
  if (lg) {
    let effectiveDate = moment(lg.effectiveDate);
    let guaranteeTenureMonth = lg?.guaranteeRequestInformation?.guaranteeTenureMonths || 0;
    return moment(effectiveDate).add(guaranteeTenureMonth, 'months').add(-1, 'days').format(BILLING_DATE_FORMAT);
  }
  return 'N/A';
};

export const ValidityDate = (lg) => {
  if (lg) {
    let maturityDate = MaturityDate(lg);
    if (moment(maturityDate, BILLING_DATE_FORMAT).isValid()) {
      return moment(maturityDate, BILLING_DATE_FORMAT).add(30, 'days').format(BILLING_DATE_FORMAT);
    }
  }
  return 'N/A';
};

export const CancellationDate = (lg) => {
  if (lg && lg.changeRequest && lg.changeRequest.length > 0) {
    let find = lg.changeRequest.find((item) => item.requestType === 'CANCELLATION');
    if (find && find.cancellationDueDate) {
      if (moment(find.cancellationDueDate).isValid()) {
        return moment(find.cancellationDueDate).format(BILLING_DATE_FORMAT);
      }
    }
  }
  let effectiveDate = lg?.effectiveDate;
  if (moment(effectiveDate).isValid()) {
    return moment(effectiveDate).add(25, 'days').format(BILLING_DATE_FORMAT);
  }
  return 'N/A';
};

// Table
export const HeadTableCell = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: '500',
    fontSize: '14px',
    height: '15px',
  },
}))(TableCell);

export const FootTableCell = withStyles(() => ({
  root: {
    backgroundColor: secondaryColor,
    color: grayColor,
    fontWeight: '500',
    fontSize: '14px',
  },
}))(TableCell);

export const StyledTableCell = withStyles((theme) => ({
  root: {
    fontSize: 14,
    color: grayColor,
    fontFamily: 'Roboto',
    paddingLeft: 3,
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.primary.main,
  },
}))(TableCell);

// Required Sign and Block Invalid Character
export const req = <span style={{ color: primaryColor }}>*</span>;
export const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

// Filter Option

export const customerType = [
  { value: 'pfi', label: <IntlMessages id="billingMgmt.pfi" /> },
  { value: 'donor', label: <IntlMessages id="billingMgmt.donor" /> },
];

export const currencys = ['USD', 'KHR'];

export const fullWordCurrency = [
  { value: 'USD', label: 'United State Dollar (USD)' },
  { value: 'KHR', label: 'Cambodian Riel (KHR)' },
];

export const lgTypes = ['Issue LG', 'Cancel LG', 'Anniversary Bill', 'Early Loan Settlement'];

export const AllLgTypes = ['Issue LG', 'Cancel LG', 'Anniversary Bill', 'Early Loan Settlement', 'Holding LG'];

export const billingCycles = ['1st Billing', '2nd Billing', '3rd Billing'];

export const billingMonths = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

export const months = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

export const accountTypes = ['Asset', 'Liability', 'Income', 'Expense'];

export const subClassifications = [
  { value: 'account_payable', label: 'Account Payable' },
  { value: 'account_receivable', label: 'Account Receivable' },
  { value: 'expense', label: 'Expense' },
  { value: 'income', label: 'Income' },
  { value: 'other_payable', label: 'Other Payable' },
  { value: 'other_receivable', label: 'Other Receivable' },
];

const today = new Date();
const getYear = moment(today).year().toString();
const currentYears = Array.from({ length: 10 }, (_, index) => (getYear - index).toString());
export const years = currentYears;

export const billingStatuses = ['Pending Bill', 'Late Bill', 'Sent Bill', 'Available Bill'];

export const paymentStatus = [
  { value: 'due', label: <IntlMessages id="due" /> },
  { value: 'partial', label: <IntlMessages id="partial.paid" /> },
  { value: 'paid', label: <IntlMessages id="paid" /> },
];

export const allInvoiceTypes = [
  { value: 'invoice', label: <IntlMessages id="tax.invoice" /> },
  { value: 'credit_note', label: <IntlMessages id="credit.note" /> },
  { value: 'debit_memo', label: 'Debit Memo' },
  { value: 'adhoc_invoice', label: 'Adhoc Invoice' },
  { value: 'adhoc_credit_note', label: 'Adhoc Credit Note' },
  // { value: 'payment_invoice', label: 'Payment' },
  { value: 'skipped_invoice', label: 'Skipped Tax Invoice' },
  { value: 'skipped_credit_note', label: 'Skipped Credit Note' },
];

export const allInvoiceTypesToDo = [
  { value: 'invoice', label: <IntlMessages id="tax.invoice" /> },
  { value: 'credit_note', label: <IntlMessages id="credit.note" /> },
  { value: 'debit_memo', label: 'Debit Memo' },
  { value: 'adhoc_invoice', label: 'Adhoc Invoice' },
  { value: 'adhoc_credit_note', label: 'Adhoc Credit Note' },
  // { value: 'payment_invoice', label: 'Payment' },
  { value: 'skip_tax_invoice', label: 'Skipped Tax Invoice' },
  { value: 'skip_credit_note', label: 'Skipped Credit Note' },
];

export const invoiceTypes = [
  { value: 'invoice', label: <IntlMessages id="tax.invoice" /> },
  { value: 'credit_note', label: <IntlMessages id="credit.note" /> },
];

export const billingTypes = [
  { value: 'issue_lg', label: 'Issue LG' },
  { value: 'cancel_lg', label: 'Cancel LG' },
  { value: 'anniversary_bill', label: 'Anniversary Bill' },
  { value: 'early_settlement', label: 'Early Loan Settlement' },
];

export const statuses = ['Invoiced', 'Skipped'];

export const monthlyStatuses = [
  { value: 'pending', label: <IntlMessages id="pending" /> },
  { value: 'partially', label: <IntlMessages id="partial.completed" /> },
  { value: 'completed', label: <IntlMessages id="completed" /> },
];

export const paymentStatuses = [
  { value: 'due', label: <IntlMessages id="due" /> },
  { value: 'overdue', label: <IntlMessages id="overdue" /> },
  { value: 'paid', label: <IntlMessages id="paid" /> },
];

export const generateStatuses = [
  { value: 'pending', label: <IntlMessages id="pending" /> },
  { value: 'partially', label: <IntlMessages id="partial.completed" /> },
  { value: 'holding', label: <IntlMessages id="holding" /> },
  { value: 'completed', label: <IntlMessages id="completed" /> },
];

export const dayNames = [
  { value: '0', label: 'Sunday' },
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
];

export const cronExpression = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
];

export const draftInvoiceStatus = [
  { value: 'pending', label: 'Pending' },
  { value: 'review', label: 'Reviewing' },
];

export const DashboardMonitoringStatuses = [
  { value: 'special_mention', label: <IntlMessages id="special.mention" /> },
  { value: 'substandard', label: <IntlMessages id="subStandard" /> },
  { value: 'doubtful', label: <IntlMessages id="doubtful" /> },
  { value: 'loss', label: <IntlMessages id="loss" /> },
];

export const monitoringStatus = ['U', 'D', 'L'];

export const reqClaimStage = [
  { value: '1', label: <IntlMessages id="first.claim" /> },
  { value: '2', label: <IntlMessages id="second.claim" /> },
];

export const statusClaimPayment = [
  { value: 'unpaid', label: 'Unpaid' },
  { value: 'partial_paid', label: 'Partial Paid' },
  { value: 'paid', label: 'Paid' },
];

export const pendingClaimStatus = [
  { value: 'unpaid', label: 'Unpaid' },
  { value: 'partial_paid', label: 'Partial Paid' },
];

export const paidPaymentStatus = [
  { value: 'partial_paid', label: 'Partial Paid' },
  { value: 'paid', label: 'Paid' },
];

export const uploadStatuses = [
  { value: 'partial', label: 'Partial' },
  { value: 'completed', label: 'Completed' },
];

export const claimTransaction = [
  { value: 'claim_request', label: 'Claim Request' },
  { value: 'claim_payment', label: 'Claim Payment' },
];

// Transaction & Billing Item Setting
export const typeOfScheme = [
  {
    value: 'individual_scheme',
    label: 'Individual Scheme',
  },
  {
    value: 'portfolio_scheme',
    label: 'Portfolio Scheme',
  },
  {
    value: 'bond_scheme',
    label: 'Bond Scheme',
  },
];

// Billing Item Setting
export const billingType = [
  { value: 'admin_fee', label: 'Admin Fee' },
  { value: 'anniversary_bill', label: 'Anniversary Bill' },
  { value: 'issue_lg', label: 'Issue LG - Gua. Fee' },
  { value: 'early_settlement', label: 'Early Settlement' },
  { value: 'cancellation', label: 'Cancellation' },
  { value: 'non_business_registration', label: 'Non-Business Registration' },
  { value: 'commitment_fee', label: 'Commitment Fee' },
];

// Transaction Setting Tab
// Transaction Type
export const transactionTypes = [
  { value: 'issue_lg', label: 'Issue LG' },
  { value: 'anniversary_bill', label: 'Anniversary Bill' },
  { value: 'issue_invoice', label: 'Issue Invoice' },
  { value: 'early_settlement', label: 'Early Settlement' },
  { value: 'fee_waiver_anniversary', label: 'Fee Waiver Anniversary' },
  { value: 'fee_waiver_early_settlement', label: 'Fee Waiver Early Settlement' },
  { value: 'accrued_monthly', label: 'Billing Accrued' },
  { value: 'accrued_general_provision', label: 'Accrued General Provision' },
  { value: 'accrued_specific_provision', label: 'Accrued Specific Provision' },
  { value: 'cancel_lg', label: 'Cancel LG' },
  { value: 'payment_invoice', label: 'Payment Invoice' },
  { value: 'first_claim', label: 'First Claim' },
  { value: 'accrued_second_claim', label: 'Accrual Second Claim' },
  { value: 'claim_payment', label: 'Claim Payment' },
  { value: 'second_claim', label: 'Second Claim' },
  { value: 'reverse_accrual_second_claim', label: 'Reverse Accrual Second Claim' },
  { value: 'recovery', label: 'Recovery' },
  { value: 'write_off', label: 'Write Off' },
];

// Billing Item
export const billingItem = [
  { value: 'default', label: 'Default' },
  { value: 'billing_item', label: 'Billing Item' },
];

// Accounting Entry
export const accountingEntry = [
  { value: 'dr', label: 'Debit' },
  { value: 'cr', label: 'Credit' },
];

// Notification Configuration
// Event Type
export const eventTypes = [
  { value: 'draft_invoice_submission', label: 'Draft Invoice Submission' },
  { value: 'draft_invoice_approval', label: 'Draft Invoice Approval' },
  { value: 'issued_billing', label: 'Issued Billing' },
  { value: 'before_invoice_due_date', label: 'Before Invoice Due Date' },
  { value: 'invoice_due_date', label: 'Invoice Due Date' },
  { value: 'overdue_date', label: 'Overdue Date' },
  { value: 'draft_payment_received_submission', label: 'Draft Payment Received Submission' },
  { value: 'draft_payment_received_approval', label: 'Draft Payment Received Approval' },
  { value: 'payment_received', label: 'Payment Received' },
  { value: 'approval_lcc', label: 'Claim Approved' },
  { value: 'draft_claim_payment_submission', label: 'Draft Claim Payment Submission' },
  { value: 'draft_claim_payment_approval', label: 'Draft Claim Payment Approval' },
  { value: 'claim_payment', label: 'Claim Payment' },
];

export const notificationSchedules = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
];

export const days = [
  { value: '0', label: 'Sunday' },
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
];

// Payment Received
export const paymentTypes = [
  { value: 'payment_received', label: 'Payment Received' },
  { value: 'payment_refund', label: 'Payment Refund' },
];

// Recovery Type
export const recoveryTypes = [
  { value: 'recovery', label: 'Recovery' },
  { value: 'recovery_write_off', label: 'Recovery Write Off' },
];

// Recovery Filter Claim Status
export const claimStatuses = [
  { value: 'hold', label: 'Put on hold' },
  { value: 'approved', label: 'Approved' },
];

// Recovery Stage
export const recoveryStage = [
  { value: 'before_second_claim', label: 'Before Second Claim' },
  { value: 'after_second_claim', label: 'After Second Claim' },
  { value: 'after_second_claim_payment', label: 'After Second Claim Payment' },
];

// Provision Type
export const provisionTypes = [
  { value: 'general', label: 'General Provision' },
  { value: 'specific', label: 'Specific Provision' },
];

// Billing Module
export const descendingComparator = (a, b, orderBy) => {
  if (orderBy === 'name' || orderBy === 'code') {
    if (b?.pfi?.[orderBy] < a?.pfi?.[orderBy] || b?.customer?.[orderBy] < a?.customer?.[orderBy]) {
      return -1;
    }
    if (b?.pfi?.[orderBy] > a?.pfi?.[orderBy] || b?.customer?.[orderBy] > a?.customer?.[orderBy]) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
};

export const stableSort = (array, comparator) => {
  if (!array) array = [{}];
  if (array && Array.isArray(array)) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  return [];
};

export const getComparator = (order, orderBy) => {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
};

// Claim Module
export const claimDescendingComparator = (a, b, orderBy) => {
  const compareString = (valueA, valueB) => {
    return valueA.toString().localeCompare(valueB.toString());
  };

  const compareNumbers = (valueA, valueB) => {
    return valueA - valueB;
  };

  const compareByOrder = (valueA, valueB) => {
    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  };

  const compareMainBorrower = () => {
    return compareString(mainBorrower(b), mainBorrower(a));
  };

  const compareMaturityDate = () => {
    const maturityDatesA = a?.loans?.map((loan) => loan.maturityDate) || [];
    const maturityDatesB = b?.loans?.map((loan) => loan.maturityDate) || [];

    maturityDatesA.sort((dateA, dateB) => compareString(dateB, dateA));
    maturityDatesB.sort((dateA, dateB) => compareString(dateB, dateA));

    return (
      compareString(maturityDatesB[0] || '', maturityDatesA[0] || '') ||
      compareString(b?.[orderBy] || '', a?.[orderBy] || '') ||
      compareString(b?.[orderBy] || '', a?.[orderBy] || '')
    );
  };

  const compareAssignableUser = () => {
    return compareString(showAssignTo(b), showAssignTo(a));
  };

  switch (orderBy) {
    case 'name':
    case 'code':
      return (
        compareString(b?.pfi?.[orderBy] || '', a?.pfi?.[orderBy] || '') ||
        compareString(b?.customer?.[orderBy] || '', a?.customer?.[orderBy] || '')
      );
    case 'borrower':
      return compareMainBorrower();
    case 'firstClaim':
      return compareString(b?.[orderBy]?.crfNo || '', a?.[orderBy]?.crfNo || '');
    case 'maturityDate':
      return compareMaturityDate();
    case 'firstClaimPayoutAmount':
    case 'secondClaimPayoutAmount':
      return compareNumbers(b?.claimInfo?.[orderBy] || 0, a?.claimInfo?.[orderBy] || 0);
    case 'loanAmount':
    case 'guaranteeRatio':
    case 'totalGuaranteeAmount':
      return (
        compareNumbers(b?.guaranteeLoanInformation?.[orderBy] || 0, a?.guaranteeLoanInformation?.[orderBy] || 0) ||
        compareNumbers(b?.guaranteeRequestInformation?.[orderBy] || 0, a?.guaranteeRequestInformation?.[orderBy] || 0)
      );
    case 'assignTo':
      return compareAssignableUser();
    case 'stage':
    case 'status':
      return compareString(b?.[orderBy] || '', a?.[orderBy] || '') || compareString(b?.[orderBy] || '', a?.[orderBy] || '');
    default:
      return compareByOrder(b[orderBy] || 0, a[orderBy] || 0);
  }
};

export const claimStableSort = (array, comparator) => {
  if (!array) array = [{}];
  if (array && Array.isArray(array)) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  return [];
};

export const claimGetComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => claimDescendingComparator(a, b, orderBy)
    : (a, b) => -claimDescendingComparator(a, b, orderBy);
};

// Recovery Module
export const recoveryDescendingComparator = (a, b, orderBy) => {
  const compareString = (valueA, valueB) => {
    return valueA.toString().localeCompare(valueB.toString());
  };

  const compareNumbers = (valueA, valueB) => {
    return valueA - valueB;
  };

  const compareByOrder = (valueA, valueB) => {
    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  };

  const compareMainBorrower = () => {
    return compareString(mainBorrower(b), mainBorrower(a));
  };

  const compareAssignableUser = () => {
    return compareString(showAssignTo(b), showAssignTo(a));
  };

  switch (orderBy) {
    case 'name':
    case 'code':
      return (
        compareString(b?.pfi?.[orderBy] || '', a?.pfi?.[orderBy] || '') ||
        compareString(b?.customer?.[orderBy] || '', a?.customer?.[orderBy] || '')
      );
    case 'borrower':
      return compareMainBorrower();
    case 'firstClaimPayoutAmount':
    case 'secondClaimPayoutAmount':
      return compareNumbers(b?.claimInfo?.[orderBy] || 0, a?.claimInfo?.[orderBy] || 0);
    case 'totalGuaranteeAmount':
      return compareNumbers(b?.guaranteeLoanInformation?.[orderBy] || 0, a?.guaranteeLoanInformation?.[orderBy] || 0);
    case 'assignTo':
      return compareAssignableUser();
    case 'stage':
    case 'status':
      return compareString(b?.[orderBy] || '', a?.[orderBy] || '') || compareString(b?.[orderBy] || '', a?.[orderBy] || '');
    default:
      return compareByOrder(b[orderBy] || 0, a[orderBy] || 0);
  }
};

export const recoveryStableSort = (array, comparator) => {
  if (!array) array = [{}];
  if (array && Array.isArray(array)) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  return [];
};

export const recoveryGetComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => recoveryDescendingComparator(a, b, orderBy)
    : (a, b) => -recoveryDescendingComparator(a, b, orderBy);
};

// Billing Accrual Module
export const billingAccrualDescendingComparator = (a, b, orderBy) => {
  const compareString = (valueA, valueB) => {
    return valueA.toString().localeCompare(valueB.toString());
  };

  const compareNumbers = (valueA, valueB) => {
    return valueA - valueB;
  };

  const compareByOrder = (valueA, valueB) => {
    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  };

  switch (orderBy) {
    case 'name':
    case 'code':
      return (
        compareString(b?.pfi?.[orderBy] || '', a?.pfi?.[orderBy] || '') ||
        compareString(b?.customer?.[orderBy] || '', a?.customer?.[orderBy] || '')
      );
    case 'totalDayPerYear':
    case 'billingDate':
    case 'issuedDate':
    case 'loanOutstanding':
    case 'guaranteeCoverage':
    case 'loanAmount':
    case 'guaranteeFee':
    case 'totalFees':
      return compareNumbers(b?.schedule?.[orderBy] || 0, a?.schedule?.[orderBy] || 0);
    default:
      return compareByOrder(b[orderBy] || 0, a[orderBy] || 0);
  }
};

export const billingAccrualStableSort = (array, comparator) => {
  if (!array) array = [{}];
  if (array && Array.isArray(array)) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  return [];
};

export const billingAccrualGetComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => billingAccrualDescendingComparator(a, b, orderBy)
    : (a, b) => -billingAccrualDescendingComparator(a, b, orderBy);
};

// Provision Module

// Type of Provision
export const typeOfProvision = [
  { value: 'general', label: 'General' },
  { value: 'specific', label: 'Specific' },
];

export const provisionDescendingComparator = (a, b, orderBy) => {
  const compareString = (valueA, valueB) => {
    return valueA.toString().localeCompare(valueB.toString());
  };

  const compareByOrder = (valueA, valueB) => {
    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  };

  switch (orderBy) {
    case 'name':
    case 'code':
      return (
        compareString(b?.pfi?.[orderBy] || '', a?.pfi?.[orderBy] || '') ||
        compareString(b?.customer?.[orderBy] || '', a?.customer?.[orderBy] || '')
      );
    case 'classification':
      return compareString(b?.[orderBy]?.['classificationName'] || '', a?.[orderBy]?.['classificationName'] || '');
    default:
      return compareByOrder(b[orderBy] || 0, a[orderBy] || 0);
  }
};

export const provisionStableSort = (array, comparator) => {
  if (!array) array = [{}];
  if (array && Array.isArray(array)) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  return [];
};

export const provisionGetComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => provisionDescendingComparator(a, b, orderBy)
    : (a, b) => -provisionDescendingComparator(a, b, orderBy);
};

//LG Management
export const handleShowStatus = (row) => {
  if (row.billingStatus !== 'cancelled' && row.billingStatus !== 'early_settlement') {
    if (row.cycle > 1) {
      return 'Anniversary Bill';
    } else {
      return 'Issued LG';
    }
  } else if (row.billingStatus === 'early_settlement') {
    return 'Early Settlement';
  } else {
    return 'Cancellation';
  }
};

export const PAYMENT_TYPE = [
  { value: 'payment_received', label: 'Payment Received' },
  { value: 'payment_refund', label: 'Payment Refund' },
];

export const LOAN_CLASSIFICATION_STATUS = [
  { value: 'normal', label: 'N' },
  { value: 'special_mention', label: 'S' },
  { value: 'substandard', label: 'U' },
  { value: 'doubtful', label: 'D' },
  { value: 'loss', label: 'L' },
];

export const DATE = [
  { value: 'day', label: 'Day(s)' },
  { value: 'month', label: 'Month(s)' },
  //   { value: 'time', label: 'Time(s)' },
  { value: 'year', label: 'Year(s)' },
];

//Convert date format for guaranteeFeePeriod
export const formatGuaranteeFeePeriod = (value) => {
  if (value && value.includes(' to ')) {
    const [startDate, endDate] = value.split(' to ');

    const formattedStartDate = moment(startDate).format('DD - MMM - YYYY');
    const formattedEndDate = moment(endDate).format('DD - MMM - YYYY');

    return `${formattedStartDate} To ${formattedEndDate}`;
  } else {
    return 'N/A';
  }
};
