import { emptySplitApi } from './index';

const extendedApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getSgbl: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_SGBL_SERVICE_UI}/sgbltop/customers`,
        method: 'GET',
        params,
      }),
      providesTags: (result = []) => [
        { type: 'REPORT_DETAIL', id: 'LIST' },
        ...result
      ]
    }),
    getSgblCompany : builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_SGBL_SERVICE_UI}/sgbltop/companys`,
        method: 'GET',
        params,
      }),
      providesTags: (result = []) => [
        { type: 'REPORT_COMPANY', id: 'LIST' },
        ...result
      ]
    }),
    getSgblCompanyModalDetail : builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_SGBL_SERVICE_UI}/sgbltop/companysmodaldetail`,
        method: 'GET',
        params,
      }),
      providesTags: (result = []) => [
        { type: 'REPORT_COMPANYDetail', id: 'LIST' },
        ...result
      ]
    }),
    getSGBLIndividual : builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_SGBL_SERVICE_UI}/sgbltop/individuals`,
        method: 'GET',
        params,
      }),
      providesTags: (result = []) => [
        { type: 'REPORT_Individual', id: 'LIST' },
        ...result
      ]
    }),
    getSGBLIndividualDetail : builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_SGBL_SERVICE_UI}/sgbltop/individualmodaldetail`,
        method: 'GET',
        params,
      }),
      providesTags: (result = []) => [
        { type: 'REPORT_IndividualDetail', id: 'LIST' },
        ...result
      ]
    }),
    getSGBLIndividualMoreDetail: builder.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_SGBL_SERVICE_UI}/sgbltop/individual/${id}`,
        method: 'GET',
      }),
      providesTags: (result =[],error,id) => [
        {
          type: 'REPORT_IndividualDetail',id
        },
        ...result
      ]
    }),

    getSGBLIndividualInfoMoreDetail: builder.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_SGBL_SERVICE_UI}/sgbltop/individualinfo/${id}`,
        method: 'GET',
      }),
      providesTags: (result =[],error,id) => [
        {
          type: 'REPORT_IndividualInfoDetail',id
        },
        ...result
      ]
    }),

    getSGBLCompanyMoreDetail: builder.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_SGBL_SERVICE_UI}/sgbltop/Company/${id}`,
        method: 'GET',
      }),
      providesTags: (result =[],error,id) => [
        {
          type: 'REPORT_CompanyDetail',id
        },
        ...result
      ]
    }),
    getSGBLCompanyInfoDetail: builder.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_SGBL_SERVICE_UI}/sgbltop/CompanyInfo/${id}`,
        method: 'GET',
      }),
      providesTags: (result =[],error,id) => [
        {
          type: 'REPORT_CompanyDetail',id
        },
        ...result
      ]
    }),
    
  }),
});

export const {
  useGetSgblQuery,
  useGetSgblCompanyQuery,
  useGetSgblCompanyModalDetailQuery,
  useGetSGBLIndividualQuery,
  useGetSGBLIndividualDetailQuery,
  useGetSGBLIndividualMoreDetailQuery,
  useGetSGBLIndividualInfoMoreDetailQuery,
  useGetSGBLCompanyMoreDetailQuery,
  useGetSGBLCompanyInfoDetailQuery,
  useGet
} = extendedApi;
