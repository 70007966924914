import axios from 'axios';

export const getAuthorizerForMasterPG = () => {
  return axios.get(`${process.env.REACT_APP_PG_SERVICE}/pg/master/authorizer`);
};

export const createMasterRequest = (body) => {
  return axios.post(`${process.env.REACT_APP_PG_SERVICE}/pg/masterRequest`, body);
};

export const updateMasterRequest = (body) => {
  return axios.post(`${process.env.REACT_APP_PG_SERVICE}/pg/updateMasterRequest`, body);
};

export const masterRequestAction = (body) => {
  return axios.post(`${process.env.REACT_APP_PG_SERVICE}/pg/masterAction`, body);
};

export const pgRequestAction = (body) => {
  return axios.post(`${process.env.REACT_APP_PG_SERVICE}/pg/pgAction`, body);
};

export const createPGRequest = async (body) => {
  return await axios
    .post(`${process.env.REACT_APP_PG_SERVICE}/pg/request`, body)
    .then((response) => {
      console.log(response.data);
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updatePGRequest = (body) => {
  return axios.post(`${process.env.REACT_APP_PG_SERVICE}/pg/updatePGRequest`, body);
};

export const pgUpdateFileToExistingMR = (body) => {
  return axios.post(`${process.env.REACT_APP_PG_SERVICE}/pg/updateFileToExistingMR`, body);
};

export const updateFileToExistingSPG = (body) => {
  return axios.post(`${process.env.REACT_APP_PG_SERVICE}/pg/updateFileToExistingSPG`, body);
};

export const pgFileDeleteExistingMR = (body) => {
  return axios.post(`${process.env.REACT_APP_PG_SERVICE}/pg/attachment/deleteExistingMR`, body);
};

export const pgFileDeleteExisitingSPG = (body) => {
  return axios.post(`${process.env.REACT_APP_PG_SERVICE}/pg/attachment/deleteExistingSPG`, body);
};
export const getNewAppsPG = (params) => {
  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee-pg/application/new`, { ...params });
};
// export const getBusinessSectorList = () => {
//   return axios.get(`${process.env.REACT_APP_PG_SERVICE}/pg/getBusinessSectorList`);
// };
