import { Box, makeStyles } from '@material-ui/core';
import React, { lazy, useEffect } from 'react';
import { Route, Switch, Link, useRouteMatch } from 'react-router-dom';
import FlatButton from '@component/Button';
import clsx from 'clsx';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { buildMultiLanguageComponent } from 'i18n/utils';
import IntlMessages from '@jumbo/utils/IntlMessages';


const indivudalSGBL = lazy(() => import('../../../modules/SGBLDashboard/sgblIndividual'));
const CompanySGBL = lazy(() => import('../../../modules/SGBLDashboard/sgblcompany'));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heading: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    cursor: 'default',
  },
  normalTab: {
    backgroundColor: 'white',
  },
}));

const SGBLHOME = ({ match, history }) => {
  const classes = useStyles();
  const requestedUrl = match.url.replace(/\/$/, '');
  const requestedPath = match.path.replace(/\/$/, '');

  const routes = [
    { url: `${requestedUrl}/individualSGBL`, path: `${requestedPath}/individualSGBL` },
    { url: `${requestedUrl}/companySGBL`, path: `${requestedPath}/companySGBL` },
  ];

  const isActive = [
    useRouteMatch(routes[0].path),
    useRouteMatch(routes[1].path)
  ];

  const heading = () => {
    return (
      <Box
        style={{ marginBottom: 10, fontSize: 24 }}
        className={classes.heading}
        onClick={() => {
          history.push('/SGBL/issued');
        }}>
        TOP Single Borrower Limit 
      </Box>
    );
  };

  const breadcrumbs = [
    { label: buildMultiLanguageComponent('Dashboard'), link: '/' },
    { label: 'TOP SGBL', link: 'admin/sgblcheck/individualSGBL', isActive: true },
    
  ];

  const tabs = [
    <Box display="flex" color="primary">
      <FlatButton
        color={isActive[0] ? 'primary' : 'default'}
        className={clsx(!isActive[0] && classes.normalTab)}
        value={routes[0].path}
        component={Link}
        style={{ marginRight: 8 }}
        to={routes[0].url}>
        Individual SBGL
      </FlatButton>

      <FlatButton
        color={isActive[1] ? 'primary' : 'default'}
        className={clsx(!isActive[1] && classes.normalTab)}
        value={routes[1].path}
        component={Link}
        to={routes[1].url}
        style={{ marginRight: 8 }}>
        Company SBGL
      </FlatButton>

    </Box>,
  ];

  useEffect(() => {
    if (!isActive.find((item) => !!item)) {
      const firstTabIndex = tabs.findIndex((item) => !!item);
      if (firstTabIndex > -1) {
        history.push(routes[firstTabIndex].url);
      }
    }
  }, [isActive]);

  return (
    <Box className={classes.root}>
      <PageContainer heading={heading()} breadcrumbs={[...breadcrumbs]}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" color="primary">
            {tabs.map((tab) => !!tab && tab)}
          </Box>
          <Switch>
            <Route path={`${requestedUrl}/individualSGBL`} component={indivudalSGBL} />
            <Route path={`${requestedUrl}/companySGBL`} component={CompanySGBL} />
          </Switch>
        </Box>
      </PageContainer>
    </Box>
  );
};

export default SGBLHOME;
