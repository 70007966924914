import axios from 'axios';

export const getCIFs = (filterOptions, searchTerm) => {
  return axios.get(`${process.env.REACT_APP_CIF_SERVICE}/cif`, { params: { filterOptions, searchTerm } });
};

export const getIndividuals = (filterOptions, searchTerm) => {
  return axios.get(`${process.env.REACT_APP_CIF_SERVICE}/cif/cgcc/individual`, { params: { filterOptions, searchTerm } });
};

export const getIndividualsExt = (condition) => {
  return axios.get(`${process.env.REACT_APP_CIF_SERVICE}/cif/cifIndividual`, { params: condition });
};

export const getCorporates = (filterOptions, searchTerm) => {
  return axios.get(`${process.env.REACT_APP_CIF_SERVICE}/cif/cgcc/corporate`, { params: { filterOptions, searchTerm } });
};

export const getCorporatesExt = (condition) => {
  return axios.get(`${process.env.REACT_APP_CIF_SERVICE}/cif/cifCorporate`, { params: condition });
};

export const getCIFIndividualById = (cifId) => {
  return axios.get(`${process.env.REACT_APP_CIF_SERVICE}/cif/individual/${cifId}`);
};

export const getCIFCorporateById = (cifId) => {
  return axios.get(`${process.env.REACT_APP_CIF_SERVICE}/cif/corporate/${cifId}`);
};

export const getCIFCompanyRelationship = (id) => {
  let url = `${process.env.REACT_APP_CIF_SERVICE}/cif/individual/companyRelationship?cid=${id}`;
  return axios.get(url, {});
};

export const getLoanByCustomerId = (cid) => {
  const url = `${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/loanIndividual?cid=${cid}`;
  return axios.get(url, {});
};

export const getLoanGuarantorByCustomerId = (ingNo, linkedNo) => {
  const url = `${process.env.REACT_APP_LG_SERVICE}/lg/individual/lg-all?indNo=${ingNo}&linkedNo=${linkedNo}`;
  return axios.get(url, {});
};
export const getBusinessGuarantorByBuId = (cid) => {
  const url = `${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/business/guarantee-cgcc?cid=${cid}`;
  return axios.get(url, {});
};

export const getAllLGOfCompany = (corpNo, linkedNo) => {
  const url = `${process.env.REACT_APP_LG_SERVICE}/lg/business/lg-all?corpNo=${corpNo}&linkedNo=${linkedNo}`;
  return axios.get(url, {});
};

export const getCIFme = () => {
  return axios.get(`${process.env.REACT_APP_CIF_SERVICE}/cif/me`);
};

export const addNewCIF = (cif) => {
  return axios.post(`${process.env.REACT_APP_CIF_SERVICE}/cif`, cif);
};

export const updateCIF = (cif) => {
  return axios.patch(`${process.env.REACT_APP_CIF_SERVICE}/cif`, cif);
};

export const deleteCIF = (cifId) => {
  return axios.delete(`${process.env.REACT_APP_CIF_SERVICE}/cif/${cifId}`);
};

export const getAllCif = (filterOptions, searchTerm) => {
  return axios.get(`${process.env.REACT_APP_CIF_SERVICE}/cif`, {
    params: { filterOptions, searchTerm },
  });
};

export const getOSGuaranteeAmount = (user, typeLoanVal, loanCurrency, rateSettingKHRUSD, loanFaciliyType, lgNo) => {
  let url = `${process.env.REACT_APP_CIF_SERVICE}/cif/user/osguarantee?typeLoanVal=${typeLoanVal}&loanCurrency=${loanCurrency}&rateKHRUSD=${rateSettingKHRUSD}`;
  if (loanFaciliyType) {
    url += `&loanFacilityType=${loanFaciliyType}`;
  }
  if (lgNo) {
    url += `&lgNo=${lgNo}`;
  }
  return axios.post(url, user);
};

export const getOSGuaranteeAmountComp = (comp, rateKHRUSD, loanFaciliyType, lgNo) => {
  let url = `${process.env.REACT_APP_CIF_SERVICE}/cif/user/company/osguarantee?rateKHRUSD=${rateKHRUSD}`;
  if (loanFaciliyType) {
    url += `&loanFacilityType=${loanFaciliyType}`;
  }
  if (lgNo) {
    url += `&lgNo=${lgNo}`;
  }
  return axios.post(url, comp);
};

export const getRiskIndividualByIndNo = (indNo) => {
  const url = `${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/individual/risk-cgcc?indNo=${indNo}`;
  return axios.get(url, {});
};
export const getRiskIndividualByCorp = (corpNo) => {
  const url = `${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/corporate/risk-cgcc?corpNo=${corpNo}`;
  return axios.get(url, {});
};

export const addRiskManualIndividual = (risk) => {
  return axios.post(`${process.env.REACT_APP_CIF_SERVICE}/cif/individual/risk`, risk);
};
export const updateRiskManualIndividual = (risk, id) => {
  return axios.put(`${process.env.REACT_APP_CIF_SERVICE}/cif/individual/risk/${id}`, risk);
};
export const addRiskManualCorporate = (risk) => {
  return axios.post(`${process.env.REACT_APP_CIF_SERVICE}/cif/corporate/risk`, risk);
};
export const updateRiskManualCorporate = (risk, id) => {
  return axios.put(`${process.env.REACT_APP_CIF_SERVICE}/cif/corporate/risk/${id}`, risk);
};
export const createBlacklistInd = (data) => {
  return axios.post(`${process.env.REACT_APP_CIF_SERVICE}/cif/blacklist/ind`, data);
};

export const updateBlacklistInd = (data) => {
  return axios.put(`${process.env.REACT_APP_CIF_SERVICE}/cif/blacklist/ind`, data);
};

export const getBlacklistInd = (condition) => {
  return axios.get(`${process.env.REACT_APP_CIF_SERVICE}/cif/individual/blacklist`, { params: condition });
};

export const createBlacklistCorp = (data) => {
  return axios.post(`${process.env.REACT_APP_CIF_SERVICE}/cif/blacklist/corp`, data);
};

export const updateBlacklistCorp = (data) => {
  return axios.put(`${process.env.REACT_APP_CIF_SERVICE}/cif/blacklist/corp`, data);
};

export const getBlacklistCorp = (condition) => {
  return axios.get(`${process.env.REACT_APP_CIF_SERVICE}/cif/corporate/blacklist`, { params: condition });
};

export const getAuthorizerForBlacklistData = () => {
  return axios.get(`${process.env.REACT_APP_CIF_SERVICE}/cif/blacklist/authorizer`);
};
export const getApplicationByAppNo = (appNo) => {
  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/lg/application?appNo=${appNo}`);
};

export const getRiskLGByLgId = (lgId) => {
  return axios.get(`${process.env.REACT_APP_LG_SERVICE}/lg/application/${lgId}/watchlist`);
};

export const addNewAppWatchList = (appId, wl) => {
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/${appId}/watchlist`, wl);
};

export const addNewAppWatchListLG = (lgId, wl) => {
  return axios.post(`${process.env.REACT_APP_LG_SERVICE}/lg/application/${lgId}/watchlist`, wl);
};

export const deleteAppWatchList = (appId, wlId) => {
  return axios.delete(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/${appId}/watchlist/${wlId}`);
};

export const deleteAppWatchListLG = (lgId, wlId) => {
  return axios.delete(`${process.env.REACT_APP_LG_SERVICE}/lg/application/${lgId}/watchlist/${wlId}`);
};

export const deleteBlacklistInd = (id) => {
  return axios.delete(`${process.env.REACT_APP_CIF_SERVICE}/cif/blacklist/ind/${id}`);
};

export const deleteBlacklistCorp = (id) => {
  return axios.delete(`${process.env.REACT_APP_CIF_SERVICE}/cif/blacklist/corp/${id}`);
};

export const submitDuplicateDataComp = (req) => {
  return axios.post(`${process.env.REACT_APP_CIF_SERVICE}/cif/duplicate-company`, req);
};

export const submitDuplicateDataInd = (req) => {
  return axios.post(`${process.env.REACT_APP_CIF_SERVICE}/cif/duplicate-individual`, req);
};

export const checkAppCheckingDuplicateIndividual = (appId) => {
  return axios.get(`${process.env.REACT_APP_CIF_SERVICE}/cif/app/linking/individual/${appId}`);
};

// export const checkAppCheckingDuplicateCompany = (appId) =>{
//   return axios.get(`${process.env.REACT_APP_CIF_SERVICE}/cif/app/linking/company/${appId}`);
// }
//
// export const submitDuplicateDataCompHandler = (id, accept) => {
//   return axios.post(`${process.env.REACT_APP_CIF_SERVICE}/cif/duplicate-company/handle/${id}?accept=${accept}`, null);
// };

export const submitDuplicateDataIndHandler = (id, accept) => {
  return axios.post(`${process.env.REACT_APP_CIF_SERVICE}/cif/duplicate-individual/handle/${id}?accept=${accept}`, null);
};

export const exportApp = (app) => {
  let url = `${process.env.REACT_APP_CIF_SERVICE}/cif/export-app`;
  let downloadResult = axios.post(url, app, {
    responseType: 'blob',
  });

  return downloadResult;
};
export const checkCorporateSGBL = (params) => {
  return axios.post(`${process.env.REACT_APP_CIF_SERVICE}/cif/corporate/detail/checking`, params);
};
export const checkIndividualSGBL = (params) => {
  return axios.post(`${process.env.REACT_APP_CIF_SERVICE}/cif/individual/detail/checking`, params);
};
