import IntlMessages from '../@jumbo/utils/IntlMessages';

export const TRANSLATE_OBJ = {
  '% of share ownership': 'percent.of.share.ownership',
  acronym: 'acronym',
  add: 'add',
  'address type': 'address.type',
  'annual turnover': 'annual.turnover',
  'annual turnover of borrowers / business': 'annual.turnover.of.borrowers./.business',
  'application binding info': 'application.binding.info',
  assessment: 'assessment',
  attachments: 'attachments',
  'authorizer has received your request.': 'authorizer.has.received.your.request.',
  'available individual': 'available.individual',
  'basic info': 'basic.info',
  'board of director': 'board.of.director',
  borrower: 'borrower',
  'borrower net profit': 'borrower.net.profit',
  'borrower relationship': 'borrower.relationship',
  'borrower total assets': 'borrower.total.assets',
  'borrower total equity': 'borrower.total.equity',
  'borrower total liability': 'borrower.total.liability',
  business: 'business',
  'business address': 'business.address',
  'business contact': 'business.contact',
  'business in negative list': 'business.in.negative.list',
  'business information': 'business.information',
  'business registration / license': 'business.registration./.license',
  'business shareholder': 'business.shareholder',
  cancel: 'cancel',
  'certification & license': 'certification.&.license',
  'cgcc scheme sector mapping': 'cgcc.scheme.sector.mapping',
  'choose authorizer for this request': 'choose.authorizer.for.this.request',
  'choose business sector': 'choose.business.sector',
  'choose request priority ': 'choose.request.priority.',
  'cif no.': 'cif.no.',
  'city/province': 'city/province',
  'company guarantor': 'company.guarantor',
  'company name': 'company.name',
  'company risk score': 'company.risk.score',
  confirm: 'confirm',
  contact: 'contact',
  'contact number': 'contact.number',
  'current address': 'current.address',
  'date of birth': 'date.of.birth',
  'debt service coverage ratio (dscr)': 'debt.service.coverage.ratio.dscr',
  'debts to equity ratio': 'debts.to.equity.ratio',
  'defining business type': 'defining.business.type',
  'describe loan purpose': 'describe.loan.purpose',
  'detail of business activities': 'detail.of.business.activities',
  documents: 'documents',
  'eligible borrower settings': 'eligible.borrower.settings',
  'email address': 'email.address',
  'estimated value of securities': 'estimated.value.of.securities',
  'expiry date': 'expiry.date',
  'family name': 'family.name',
  'file name': 'file.name',
  'first name': 'first.name',
  'full name': 'full.name',
  gender: 'gender',
  'guarantee application binding information': 'guarantee.application.binding.information',
  'guarantee management': 'guarantee.management',
  'guarantee ratio': 'guarantee.ratio',
  'guarantee request information': 'guarantee.request.information',
  'guarantee settings': 'guarantee.settings',
  'guarantor value': 'guarantor.value',
  home: 'home',
  'house & street': 'house.&.street',
  'if no kscore, please specify here': 'if.no.kscore,.please.specify.here',
  'individual guarantor': 'individual.guarantor',
  'individual no.': 'individual.no.',
  information: 'information',
  'interest rate per-annum': 'interest.rate.per-annum',
  'issue date': 'issue.date',
  'khan/district': 'khan/district',
  kscore: 'kscore',
  loan: 'loan',
  'loan amount': 'loan.amount',
  'loan application number': 'loan.application.number',
  'loan criteria': 'loan.criteria',
  'loan facility': 'loan.facility',
  'loan purpose': 'loan.purpose',
  'loan tenure (months)': 'loan.tenure.months',
  'main business activity': 'main.business.activity',
  'main sector': 'main.sector',
  'maximum value is 100': 'maximum.value.is.100',
  'minimum value is usd 1,000': 'minimum.value.is.usd.1,000',
  nationality: 'nationality',
  'negative list': 'negative.list',
  'new borrower': 'new.borrower',
  'new guarantee application request': 'new.guarantee.application.request',
  'new individual': 'new.individual',
  'no.': 'no.',
  'not applicable option': 'not.applicable.option',
  'not available': 'not.available',
  'number of full-time staff': 'number.of.full-time.staff',
  'official identification': 'official.identification',
  'official identity type': 'official.identity.type',
  'pfi branch': 'pfi.branch',
  'pfi remarks': 'pfi.remarks',
  "pfi's experience with main borrowers": 'pfi.s.experience.with.main.borrowers',
  'phumi/village': 'phumi.village',
  'please upload all of the required attachment files.': 'please.upload.all.of.the.required.attachment.files.',
  priority: 'priority',
  'progress steps': 'progress.steps',
  'reference no.': 'reference.no.',
  remove: 'remove',
  'request submitted successfully': 'request.submitted.successfully',
  'required document': 'required.document',
  'risk grade': 'risk.grade',
  'sangkat/commune': 'sangkat/commune',
  'scheme end': 'scheme.end',
  'scheme size (millions)': 'scheme.size.millions',
  'scheme start': 'scheme.start',
  'score range': 'score.range',
  'security type': 'security.type',
  select: 'select',
  'show on lg': 'show.on.lg',
  summary: 'summary',
  'summary data': 'summary.data',
  'the maximum loan amount must be usd 200,000': 'the.maximum.loan.amount.must.be.usd.200,000',
  'there is no data selected.': 'there.is.no.data.selected.',
  title: 'title',
  'total asset': 'total.asset',
  'total estimated amount': 'total.estimated.amount',
  'total loan amount': 'total.loan.amount',
  'type in': 'type.in',
  'type of borrower / business': 'type.of.borrower./.business',
  'type of business constituency': 'type.of.business.constituency',
  'type of securities': 'type.of.securities',
  'valid enterpise type': 'valid.enterpise.type',
  website: 'website',
  yes: 'yes',
  'you may follow up this request status in guarantee application':
    'you.may.follow.up.this.request.status.in.guarantee.application',
  'new application': 'new.application',
  'authorizer review': 'authorizer.review',
  'there is no guarantee scheme': 'there.is.no.guarantee.scheme',
  'choose guarantee scheme': 'choose.guarantee.scheme',
  version: 'version',
  'if you change scheme type all data will be reset. are you sure ?':
    'if.you.change.scheme.type.all.data.will.be.reset..are.you.sure.?',
  'cannot select multi main borrower': 'cannot.select.multi.main.borrower',
  'loan facility is required. ': 'loan.facility.is.required..',
  'security type is required. ': 'security.type.is.required..',
  'guarantee tenure (months) is not valid.': 'guarantee.tenure.months).is.not.valid.',
  'business must not in negative list': 'business.must.not.in.negative.list',
  'business registration is required. ': 'business.registration.is.required..',
  'shareholder is required. ': 'shareholder.is.required..',
  'the total of shareholder ownership must be 100. current total:':
    'the.total.of.shareholder.ownership.must.be.100..current.total:',
  'please input valid phone number': 'please.input.valid.phone.number',
  'this scheme is only available for ': 'this.scheme.is.only.available.for.',
  'the required attachment files have to be checked.': 'the.required.attachment.files.have.to.be.checked.',
  'file is not uploaded completely or failed please wait or re-upload. file name:':
    'file.is.not.uploaded.completely.or.failed.please.wait.or.re-upload..file.name:',
  'at least a main borrower or representative have to be chosen.':
    'at.least.a.main.borrower.or.representative.have.to.be.chosen.',
  'please select type of main borrower': 'please.select.type.of.main.borrower',
  'for individual main borrower, there is no representative borrower. main borrower is required.':
    'for.individual.main.borrower,.there.is.no.representative.borrower..main.borrower.is.required.',
  'for company main borrower, there is no main borrower. representative is required.':
    'for.company.main.borrower,.there.is.no.main.borrower..representative.is.required.',
  'type of main borrower': 'type.of.main.borrower',
  'save as draft': 'save.as.draft',
  'nbc additional level': 'nbc.additional.level',
  ' nbc level': '.nbc.level',
  'created date': 'created.date',
  'app id': 'app.id',
  'draft name': 'draft.name',
  'my draft': 'my.draft',
  scheme: 'scheme',
  'last update on': 'last.update.on',
  status: 'status',
  actions: 'actions',
  'there is no borrower for this application yet, please add new borrower or choose an existing one.':
    'there.is.no.borrower.for.this.application.yet,.please.add.new.borrower.or.choose.an.existing.one.',
  'businesses and/or loan purposes listed below are ineligible for guarantee:':
    'businesses.and/or.loan.purposes.listed.below.are.ineligible.for.guarantee:',
  'current year': 'current.year',
  'new guarantee': 'new.guarantee',
  'add new guarantee': 'add.new.guarantee',
  'edit guarantee': 'edit.guarantee',
  'guarantee information': 'guarantee.information',
  'definition of enterprise': 'definition.of.enterprise',
  'formular enterprises': 'formular.enterprises',
  'general setting': 'general.setting',
  'check sbgl': 'check.sbgl',
  'check sbgl for customer': 'check.sbgl.for.customer',
  'this field is required': 'this.field.is.required',
  required: 'required',
  'linking manual': 'linking.manual',
  "the search information is not available in the system's data":
    "the.search.information.is.not.available.in.the.system's.data",
  recommendation: 'recommendation',
  'your recommendation': 'your.recommendation',
  'not recommend': 'not.recommend',
  recommend: 'recommend',
  'submit for cop recommend': 'submit.for.cop.recommend',
  'choose approver': 'choose.approver',
  'need more info': 'need.more.info',
  submit: 'submit',
  'remaining guarantee amount': 'remaining.guarantee.amount',
  'exceed requested guarantee amount': 'exceed.requested.guarantee.amount',
  'list of individual': 'list.of.individual',
  'pfi company': 'pfi.company',
  'single borrower limit detail': 'single.borrower.limit.detail',
  'risk level detail': 'risk.level.detail',
  'blacklist detail': 'blacklist.detail',
  'debt service coverage ratio (dscr) is not valid.': 'debt.service.coverage.ratio.dscr).is.not.valid.',
  'debt service coverage ratio (dscr) is valid.': 'debt.service.coverage.ratio.dscr).is.valid.',
  'list of companies': 'list.of.companies',
  'women entrepreneurs guarantee scheme': 'women.entrepreneurs.guarantee.scheme',
  'definition of enterprises': 'definition.of.enterprises',
  'valid enterprise type': 'valid.enterprise.type',
  'business sector': 'business.sector',
  'annual turnover (usd)': 'annual.turnover.usd',
  'annual turnover code': 'annual.turnover.code',
  'total asset (usd)': 'total.asset.usd',
  'total asset code': 'total.asset.code',
  'number of employees': 'number.of.employees',
  'number of employees code': 'number.of.employees.code',
  'priority sectors': 'priority.sectors',
  'non-priority sectors': 'non-priority.sectors',
  'agriculture sector': 'agriculture.sector',
  'industry sector': 'industry.sector',
  others: 'others',
  'guarantee fee': 'guarantee.fee',
  unit: 'unit',
  'maximum tenure (years)': 'maximum.tenure.years',
  'type of loan': 'type.of.loan',
  'loan purposes': 'loan.purposes',
  'loan currencies': 'loan.currencies',
  'maximum loan amount': 'maximum.loan.amount',
  'non-priority sector': 'non-priority.sector',
  'large firm': 'large.firm',
  '1st year interest rate': '1st.year.interest.rate',
  'single borrower guarantee limit': 'single.borrower.guarantee.limit',
  'business type': 'business.type',
  amount: 'amount',
  'majority cambodian-owned business': 'majority.cambodian-owned.business',
  'majority owned business gender': 'majority.owned.business.gender',
  'majority owned business shares (>)': 'majority.owned.business.shares',
  'accept non-registerred business': 'accept.non-registerred.business',
  'additional guarantee fee': 'additional.guarantee.fee',
  'additional guarantee fee (unit)': 'additional.guarantee.fee.unit',
  no: 'no',
  'step name': 'step.name',
  description: 'description',
  display: 'display',
  'are you sure, you want to  delete this record?': 'are.you.sure,.you.want.to..delete.this.record?',
  'add data': 'add.data',
  'search...': 'search...',
  'cop review': 'cop.review',
  'guarantee application review & recommendation': 'guarantee.application.review.&.recommendation',
  "compliance with cgcc's scheme": "compliance.with.cgcc's.scheme",
  'set parameter': 'set.parameter',
  'current value': 'current.value',
  'k-score': 'k-score',
  'facility type': 'facility.type',
  'interest rate': 'interest.rate',
  'total collateral value': 'total.collateral.value',
  'guaranteed amount': 'guaranteed.amount',
  'existing borrower(s) with pfi for years': 'existing.borrower(s).with.pfi.for.years',
  'loan tenure (month)': 'loan.tenure.month',
  'detail of loan purpose': 'detail.of.loan.purpose',
  'summary of business background': 'summary.of.business.background',
  'describe business activity': 'describe.business.activity',
  'if other, please specify': 'if.other,.please.specify',
  'business sector priority type': 'business.sector.priority.type',
  'business sub-sector': 'business.sub-sector',
  'your comment': 'your.comment',
  comment: 'comment',
  'review by': 'review.by',
  date: 'date',
  'guarantee application document check list': 'guarantee.application.document.check.list',
  document: 'document',
  check: 'check',
  remark: 'remark',
  recommended: 'recommended',
  'not recommended': 'not.recommended',
  'review date': 'review.date',
  'brgs,cfgs-tr,wegs': 'brgs.cfgs.tr.wegs',
  'scheme type': 'scheme.type',
  'micro, small, medium, large': 'micro.small.medium.large',
  'single borrower guarantee limit (sbgl)': 'single.borrower.guarantee.limit.sbgl',
  'cambodian & non-cambodian': 'cambodian.non.cambodian',
  'majority shareholder (nationality)': 'majority.shareholder.nationality',
  'male & female': 'male.female',
  'majority shareholder (gender)': 'majority.shareholder.gender',
  'priority & non-priority': 'priority.non.priority',
  'guarantee coverage ratio': 'guarantee.coverage.ratio',
  'financial viable': 'financial.viable',
  'yes / no': 'yes.no',
  'low, medium, high': 'low.medium.high',
  'risk level': 'risk.level',
  blacklist: 'blacklist',
  'cgcc cover letter': 'cgcc.cover.letter',
  "borrower's loan application": 'borrowers.loan.application',
  "pfi's loan appraisal report": 'pfi.s.loan.appraisal.report',
  "borrower's cbc report": "borrower's.cbc.report",
  "borrower's shareholder/director's official identity documents":
    "borrower's.shareholder/director's.official.identity.documents",
  'borrower business registration and/or licenses': 'borrower.business.registration.and.or.licenses',
  "security's evidence (hard title, soft title, ownership certificate, etc.)":
    "security's.evidence.hard.title.soft.title.ownership.certificate.etc",
  "report on borrower's security valuation (either internal or external)":
    "report.on.borrower's.security.valuation.either.internal.or.external",
  "pfi's loan letter of offer (including terms & conditions of the loan)":
    'pfi.s.loan.letter.of.offer.including.terms.&.conditions.of.the.loan',
  "evidence of sme bank of cambodia's approval of the loan drawdown":
    "evidence.of.sme.bank.of.cambodia's.approval.of.the.loan.drawdown",
  "borrower's consent for the disclosure and usage of their information by cgcc":
    "borrower's.consent.for.the.disclosure.and.usage.of.their.information.by.cgcc",
  'business photo': 'business.photo',
  return: 'return',
  'submit for cop': 'submit.for.cop',
  'please select': 'please.select',
  'last name': 'last.name',
  'reference number': 'reference.number',
  'select an items': 'select.an.items',
  'certificate & license': 'certificate.&.license',
  'individual no': 'individual.no',
  'reference no': 'reference.no',
  'company no': 'company.no',
  match: 'match',
  type: 'type',
  currency: 'currency',
  previous: 'previous',
  "report on borrower's security valuation (either internal or external) ":
    'report.on.borrowers.security.valuation.either.internal.or.external',
  "evidence of sme ank of cambodia's approval of the loan drawdown":
    'evidence.of.sme.bank.of.cambodias.approval.of.the.loan.drawdown',
  'business photos': 'business.photos',
  'lgs management': 'lgs.management',
  dashboard: 'dashboard',
  'canceled lg': 'canceled.lg',
  'early settlement lg': 'early.settlement.lg',
  'amended lg': 'amended.lg',
  'restructured lg': 'restructured.lg',
  'matured lg': 'matured.lg',
  'issued lg': 'issued.lg',
  'all schemes': 'all.schemes',
  'all pfi': 'all.pfi',
  'please choose time': 'please.choose.time',
  close: 'close',
  'start date must be less than end date': 'start.date.must.be.less.than.end.date',
  'start date and end date must be less than or equal to 1 year':
    'start.date.and.end.date.must.be.less.than.or.equal.to.1.year',
  newest: 'newest',
  oldest: 'oldest',
  'all currency': 'all.currency',
  'all biz reg': 'all.biz.reg',
  registered: 'registered',
  'non-registered': 'non.registered',
  export: 'export',
  'lg no.': 'lg.no',
  flag: 'flag',
  'rows per page': 'rows.per.page',
  'no data available.': 'no.data.available',
  'biz reg status': 'biz.reg.status',
  'total g. amount': 'total.g.amount',
  'g. coverage': 'g.coverage',
  'total amount': 'total.amount',
  'effective date': 'effective.date',
  'issued date': 'issued.date',
  'main borrower': 'main.borrower',
  'ref lg no.': 'ref.lg.no',
  detail: 'detail',
  'go to gaf': 'go.to.gaf',

  'all month': 'all.month',
  january: 'january',
  february: 'february',
  march: 'march',
  april: 'april',
  may: 'may',
  june: 'june',
  july: 'july',
  august: 'august',
  september: 'september',
  october: 'october',
  november: 'november',
  december: 'december',
  'non-performing loan (npl)': 'non.performing.loan.npl',
  's: special mention': 's.special.mention',
  'performing loan (pl)': 'performing.loan.pl',
  'l: loss': 'l.loss',
  'u: substandard': 'u.substandard',
  'd: doubtful': 'd.doubtful',
  total: 'total',
  'create invoice': 'billingMgmt.create.invoice',
  'adhoc invoice': 'adhoc.invoice',
  'adhoc credit note': 'adhoc.credit.note',
  'special mention': 'special.mention',
  doubtful: 'doubtful',
  substandard: 'subStandard',
  'sub-standard': 'sub-standard',
  loss: 'loss',
  u: 'u',
  d: 'd',
  l: 'l',
  ' % of share ownership': '.%.of.share.ownership',
  'total principal outstanding in equi.': 'total.principal.outstanding.in.equi.',
  'total npl guaranteed account': 'total.npl.guaranteed.account',
  'total npl guaranteed amount (usd mil)': 'total.npl.guaranteed.amount.usd.mil',
  'accumulated disbursement amount': 'accumulated.disbursement.amount',
  'accumulated interest amount repayment': 'accumulated.interest.amount.repayment',
  'accumulated outstanding interest': 'accumulated.outstanding.interest',
  'accumulated penalty amount repayment': 'accumulated.penalty.amount.repayment',
  'accumulated principal amount repayment': 'accumulated.principal.amount.repayment',
  'accumulated recovery amount': 'accumulated.recovery.amount',
  'accumulated recovery cost': 'accumulated.recovery.cost',
  'all application': 'all.application',
  'all report': 'all.report',
  'all status': 'all.status',
  'all year': 'all.year',
  'amount (usd)': 'amount.usd',
  application: 'application',
  'application (renewal)': 'application.renewal',
  approval: 'approval',
  'approval group created successfully': 'approval.group.created.successfully',
  'approval group management': 'approval.group.management',
  'approval group name': 'approval.group.name',
  'approval group short-name': 'approval.group.short-name',
  'approval group updated successfully': 'approval.group.updated.successfully',
  'approval status': 'approval.status',
  approve: 'approve',
  'approved application': 'approved.application',
  'approved by': 'approved.by',
  'approved date': 'approved.date',
  'are you sure, you want to delete this record?': 'are.you.sure.you.want.to.delete.this.record?',
  'as of date': 'as.of.date',
  'as of month': 'as.of.month',
  'assigned to': 'assigned.to',
  'attachment result': 'attachment.result',
  'awaiting approval': 'awaiting.approval',
  'cgcc information': 'cgcc.information',
  'cgcc review': 'cgcc.review',
  'cgcc trigger state': 'cgcc.trigger.state',
  checker: 'checker',
  claim: 'claim',
  'column name': 'column.name',
  comments: 'comments',
  'completed report': 'completed.report',
  'confirm delete': 'confirm.delete',
  'cop recommend': 'cop.recommend',
  'cop recommendation': 'cop.recommendation',
  'cop trigger': 'cop.trigger',
  'current classification status': 'current.classification.status',
  'current upload': 'current.upload',
  'date of first disbursement': 'date.of.first.disbursement',
  'date of loan classification status changed': 'date.of.loan.classification.status.changed',
  deadline: 'deadline',
  'delete approval group succesfully': 'delete.approval.group.succesfully',
  'doubtful (d)': 'doubtful.d',
  'error description': 'error.description',
  'error lgs': 'error.lgs',
  'error summary': 'error.summary',
  'export lgs': 'export.lgs',
  fail: 'fail',
  'fail to approve the report.': 'fail.to.approve.the.report.',
  'flow types': 'flow.types',
  'g. account npl ratio': 'g..account.npl.ratio',
  'ga no.': 'ga.no.',
  'ga stage': 'ga.stage',
  'ga state': 'ga.state',
  'greater than & equal to': 'greater.than.&.equal.to',
  'guarantee amount': 'guarantee.amount',
  'guarantee amount validation': 'guarantee.amount.validation',
  'guarantee application': 'guarantee.application',
  'guarantee loan': 'guarantee.loan',
  'guaranteed amount npl by pfi as of ': 'guaranteed.amount.npl.by.pfi.as.of.',
  'history report npl': 'history.report.npl',
  'last uploaded': 'last.uploaded',
  late: 'late',
  'less than & equal to': 'less.than.&.equal.to',
  'lg no': 'lg.no',
  'lg number': 'lg.number',
  'lg renewal': 'lg.renewal',
  'loan account in ga': 'loan.account.in.ga',
  'loan account number': 'loan.account.number',
  'loan classification change': 'loan.classification.change',
  'loan classification status': 'loan.classification.status',
  'loan currency': 'loan.currency',
  'loan facility type': 'loan.facility.type',
  'loan tenure month': 'loan.tenure.month',
  'loss (l)': 'loss.l',
  maker: 'maker',
  'maturity date': 'maturity.date',
  million: 'million',
  'monitoring form': 'monitoring.form',
  'month report': 'month.report',
  'monthly uploading report': 'monthly.uploading.report',
  'new approval group': 'new.approval.group',
  'no approval group': 'no.approval.group',
  'no data available': 'no.data.available',
  'non-performing report (last month)': 'non-performing.report.last.month',
  operator: 'operator',
  'original amount': 'original.amount',
  'outstanding balance': 'outstanding.balance',
  'overdue started date': 'overdue.started.date',
  owner: 'owner',
  'partcipating financial institutions': 'partcipating.financial.institutions',
  'participating financial institutions': 'participating.financial.institutions',
  pass: 'pass',
  pending: 'pending',
  'pending report': 'pending.report',
  pfi: 'pfi',
  'pfi id': 'pfi.id',
  'pfi monthly performance': 'pfi.monthly.performance',
  'pfi review': 'pfi.review',
  'pfi upload state': 'pfi.upload.state',
  'pfi uploaded state': 'pfi.uploaded.state',
  'please choose an export file format!': 'please.choose.an.export.file.format!',
  'prepared by': 'prepared.by',
  'previous classification status': 'previous.classification.status',
  'previous upload': 'previous.upload',
  'principal outstanding balance': 'principal.outstanding.balance',
  'Principal Loan Outstanding Amount': 'principal.loan.outstanding.amount',
  'r & r status': 'r.&.r.status',
  'reason of default': 'reason.of.default',
  reject: 'reject',
  'rejected application': 'rejected.application',
  'rejected date': 'rejected.date',
  'reported date': 'reported.date',
  'request priority': 'request.priority',
  'require to fill': 'require.to.fill',
  restructure: 'restructure',
  'restructure, loan tenure < 7': 'restructure.loan.tenure.<.7',
  'restructure, loan tenure >= 7': 'restructure.loan.tenure.>=.7',
  'returned application': 'returned.application',
  'review priority': 'review.priority',
  reviewing: 'reviewing',
  'reviewing stage & user group': 'reviewing.stage.&.user.group',
  'risk recommend': 'risk.recommend',
  'risk recommendation': 'risk.recommendation',
  save: 'save',
  'scheme version': 'scheme.version',
  search: 'search',
  'search here': 'search.here',
  'search here...': 'search.here...',
  'search month': 'search.month',
  'search year': 'search.year',
  'select flow types': 'select.flow.types',
  'short name': 'short.name',
  'showing base on selected filters': 'showing.base.on.selected.filters',
  'showing based on selected filter': 'showing.based.on.selected.filter',
  stage: 'stage',
  steps: 'steps',
  'submission date': 'submission.date',
  'submitted date': 'submitted.date',
  'submitting to authorizer': 'submitting.to.authorizer',
  'substandard (u)': 'substandard.u',
  'sync lgs': 'sync.lgs',
  'the report has been approved successfully.': 'the.report.has.been.approved.successfully.',
  'total approved application': 'total.approved.application',
  'total approved guarantee amount in khr': 'total.approved.guarantee.amount.in.khr',
  'total approved guarantee amount in usd': 'total.approved.guarantee.amount.in.usd',
  'total late monthly report': 'total.late.monthly.report',
  'total letter guarantee': 'total.letter.guarantee',
  'total lgs': 'total.lgs',
  'total lgs in khr': 'total.lgs.in.khr',
  'total lgs in usd': 'total.lgs.in.usd',
  'total new application': 'total.new.application',
  'total participating financial institution': 'total.participating.financial.institution',
  'total pending letter of guarantee': 'total.pending.letter.of.guarantee',
  'total pending monthly report': 'total.pending.monthly.report',
  'total rejected application': 'total.rejected.application',
  'total rejected guarantee amount in khr': 'total.rejected.guarantee.amount.in.khr',
  'total rejected guarantee amount in usd': 'total.rejected.guarantee.amount.in.usd',
  'total uploaded letter of guarantee': 'total.uploaded.letter.of.guarantee',
  'type in amount': 'type.in.amount',
  'type in approval group name': 'type.in.approval.group.name',
  'type in approval group short-name': 'type.in.approval.group.short-name',
  'type in description': 'type.in.description',
  update: 'update',
  upload: 'upload',
  'upload result': 'upload.result',
  urgent: 'urgent',
  'user group': 'user.group',
  'view error summary': 'view.error.summary',
  'waiting approval report': 'waiting.approval.report',

  "evidence of sme bank of cambodia's approval of the loan drawdown ":
    'evidence.of.sme.bank.of.cambodias.approval.of.the.loan.drawdown',
  male: 'male',
  female: 'Female',
  found: 'found',
  'list of individuals': 'list.of.individuals',
  remarks: 'remarks',
  link: 'link',
  linking: 'linking',
  'duplicate individuals': 'duplicate.individuals',
  'choose authorizer of this request': 'choose.authorizer.of.this.request',
  notice: 'notice',
  'this request will be sent to authorizer of your choosing.': 'this.request.will.be.sent.to.authorizer.of.your.choosing',
  'you will receive a notification if your request is approved by an authorizer.':
    'you.will.receive.a.notification.if.your.request.is.approved.by.an.authorizer',
  'if your request is rejected, you may find and re-submit again in':
    'if.your.request.is.rejected.you.may.find.and.re.submit.again.in',
  'to do > request by me. ': 'to.do.request.by.me',
  'tax invoice': 'tax.invoice',
  'available bill': 'available.bill',
  'late bill': 'late.bill',
  'sent bill': 'sent.bill',
  'pending bill': 'pending.bill',
  due: 'due',
  paid: 'paid',
  'partial paid': 'partial.paid',
  'the borrower who is a non-registered business must proceed with the registration after the guarantee is approved. if the borrower remains a non-registered business, an additional guarantee fee of 0.5% per annum of the guaranteed amount will be imposed on every anniversary of the guarantee.':
    'claim.the.borrower.who.is.a.non-registered.business.must.proceed.with.the.registration.after.the.guarantee.is.approved.if.the.borrower.remains.a.non-registered.business.an.additional.guarantee.fee.of.0.5%.per.annum.of.the.guaranteed.amount.will.be.imposed.on.every.anniversary.of.the.guarantee',
  'guarantee coverage ratio is a percentage of outstanding loan principal amount for term loans. for od, tf or other similar facilities, the guarantee coverage ratio is a percentage of annual approved limits.':
    'claim.guarantee.coverage.ratio.is.a.percentage.of.outstanding.loan.principal.amount.for.term.loans.for.od.tf.or.other.similar.facilities.the.guarantee.coverage.ratio.is.a.percentage.of.annual.approved.limits',
  'guarantee fee is per annum as a percentage of the outstanding guaranteed amount at the beginning of each billing cycle of each guaranteed loan. for od, tf or other similar facilities, the guarantee fees will be charged on the annual approved limits. guarantee fee is subject to taxes as required by cambodian tax laws.':
    'claim.guarantee.fee.is.per.annum.as.a.percentage.of.the.outstanding.guaranteed.amount.at.the.beginning.of.each.billing.cycle.of.each.guaranteed.loan.for.od.tf.or.other.similar.facilities.the.guarantee.fees.will.be.charged.on.the.annual.approved.limits.guarantee.fee.is.subject.to.taxes.as.required.by.cambodian.tax.laws',
  'pfi shall notify cgcc within 25 calendar days after the date of lg issuance if the pfi wish to cancel the guaranteed loan.':
    'claim.pfi.shall.notify.cgcc.within.25.calendar.days.after.the.date.of.lg.issuance.if.the.pfi.wish.to.cancel.the.guaranteed.loan',
  'this lg is effective subject to compliance with all terms and conditions prescribed in the scheme guideline and credit guarantee agreement.':
    'claim.this.lg.is.effective.subject.to.compliance.with.all.terms.and.conditions.prescribed.in.the.scheme.guideline.and.credit.guarantee.agreement',
  'pfi creation stage': 'pfi.creation.stage',
  'cop review stage': 'cop.review.stage',
  'cop recommendation stage': 'cop.recommendation.stage',
  'approval stage': 'approval.stage',
  monday: 'monday',
  tuesday: 'tuesday',
  wednesday: 'wednesday',
  thursday: 'thursday',
  friday: 'friday',
  saturday: 'saturday',
  sunday: 'sunday',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  invoiced: 'invoiced',
  skipped: 'skipped',
  asset: 'asset',
  liability: 'liability',
  income: 'income',
  expense: 'expense',
  'account payable': 'account.payable',
  'account receivable': 'account.receivable',
  'other payable': 'other.payable',
  'other receivable': 'other.receivable',
  'issue lg': 'issue.lg',
  'cancel lg': 'cancel.lg',
  'anniversary bill': 'anniversary.bill',
  'early loan settlement': 'early.loan.settlement',
  'holding lg': 'holding.lg',
  'credit note': 'credit.note',
  payment: 'payment',
  'skip tax invoice': 'skip.tax.invoice',
  'skip credit note': 'skip.credit.note',
  'debit memo': 'debit.memo',
  'pfi report on loan loss to nbc': 'claim.pfi.report.on.loan.loss.to.nbc',
  'demand notice letter to borrowers': 'claim.demand.notice.letter.to.borrowers',
  'copy of latest cbc report on the date of claim request': 'claim.copy.of.latest.cbc.report.on.date.of.claim.request',
  'copy of loan statement and loan repayment (from disbursement date to date of claim request submission)':
    'claim.copy.of.loan.statement.and.loan.repayment.from.disbursement.date.to.date.of.claim.request.submission',
  'report of recovery action taken including site visit report':
    'claim.report.of.recovery.action.taken.including.site.visit.report',
  'proof of the legal/foreclosure action against the borrower':
    'claim.proof.of.the,legal/foreclosure.action.against.the.borrowers',
  'copy of the loan agreement': 'claim.copy.of.the.loan.agreement',
  'letter of guarantee': 'claim.letter.of.guarantee',
  'other supporting documents': 'claim.other.supporting.documents',
  'gaf checklist review': 'claim.gaf.checklist.review',
  'claim document checklist': 'claim.claim.document.checklist',
  other: 'claim.other',
  unpaid: 'unpaid',
  'claim request': 'sidebar.claim.request',
  'claim payment': 'claim.payment',
  'first claim payout': 'claim.first.claim.payout',
  'second claim payout': 'claim.second.claim.payout',
  'a copy of loan statement/loan repayment schedule/account statement to prove loan outstanding amount for npl.':
    'LoanMonitoring.a.copy.of.loan.statement.loan.repayment.schedule.account.statement.to.prove.loan.outstanding.amount.for.npl.',
  'a copy of cbc report': 'loanMonitoring.a.copy.of.cbc.report',
  'end of month': 'end.of.month',
};

export function buildMultiLanguageComponent(desc) {
  if (desc) {
    let id = TRANSLATE_OBJ[desc.toString().toLowerCase()];
    if (id) {
      return <IntlMessages id={id} defaultMessage={desc} />;
    }
    return desc.toString();
  }
  return desc;
}
